import {
    MDBIcon,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBAnimation,
} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import { dataFormatada } from "../../../services/utils";


const ListaFretes = ({listaDeFretes, setIdInfoFrete}: any) => {
    return (
        <MDBAnimation type="fadeIn">
            <MDBTable className="mb-5" responsive>
                <MDBTableHead color="background-azul" textWhite>
                    <tr>
                        <th>Origem</th>
                        <th>Destino</th>
                        <th>Data e Horário</th>
                        <th>Natureza</th>
                        <th>Preço</th>
                        <th>&nbsp;Detalhes</th>
                        <th>Empresa</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {listaDeFretes.map((frete: any) => {
                        return (
                            <tr className="table-body-hover" key={frete.id}>
                                <td>{frete.originCity.description+" - " + frete.originState.description}</td>
                                <td>{frete.destinationCity.description+" - " + frete.destinationState.description}</td>
                                <td>{dataFormatada(frete.freight_date)} - {frete.time}</td>
                                <td>{frete.cargoType.description}</td>
                                <td>{"R$ " + frete.price}</td>
                                <td>
                                    <a onClick={() => setIdInfoFrete(frete.id)} className="padding-10">
                                        <MDBIcon icon="eye" className="color-azul" />&nbsp;
                                    </a>
                                </td>
                                <td>{frete.company.name}</td>
                            </tr>
                        )
                    })}
                </MDBTableBody>
            </MDBTable>
        </MDBAnimation>
    )

}

export default ListaFretes;