import '../../styles/login.css';
import { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBMask,
    MDBInput,
    MDBView,
    MDBBtn,
    MDBIcon,
    //@ts-ignore
    toast,
} from 'mdbreact';
import { useHistory } from "react-router-dom";
import logoColorida from '../../images/logoColorida.svg';
import appApi from '../../services/appApi';

const Login = ({ toggleParteEsqueciMinhaSenha, toggleParteCadastro }: any) => {

    interface IFormData {
        email: string,
        password: string
    }

    type  IInputName = 'email' | 'password'

    const [formData, setFormData] = useState<IFormData>({} as IFormData);

    let history = useHistory();
    const token = "@shareeconomy_token"
    
    const handleFormChange = (event: any) => {
        const inputName: IInputName = event.target.name;
        formData[inputName] = event.target.value;
        setFormData(formData);
    }


    const logarUsuario = (event: any) => {
        event.preventDefault();
        appApi.post("/login", formData).then((response) => {
            if(response.status === 200){
                sessionStorage.setItem(token, response.data.token);
                history.push("/espacos-disponiveis");
            }
        }).catch((error) => {
            if(error.response.status === 400){
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Usuário ou senha incorretos</span>, {
                    closeButton: false
                });
            } else {
                return toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte</span>, {
                    closeButton: false
                });
            }
        });
    };

    return (
        <div id="parte-login">
            <MDBView>
                <MDBMask>
                    <div id="div-fundo">
                        <div id="fundo-login-cadastro">
                            <div id="login-cadastro">
                                <MDBContainer>
                                    <MDBRow>
                                        <div className="text-center w-100 mt-5 mb-5">
                                            <a href="/">
                                                <img src={logoColorida} width="300px" className="d-inline-block" alt="Logo Share Economy" />
                                            </a>
                                        </div>
                                    </MDBRow>
                                    <MDBRow id="forms-login-cadastro" className="w-100 mb-5">
                                        <MDBCol className="mt-5 mx-auto" md="10" lg="6" xl="5" sm="12">
                                            <MDBCard className="card-image background-amarelo border-radius-20">
                                                <div className="text-white py-5 px-5 z-depth-4" id="form-login">
                                                    <div className="text-center">
                                                        <h3 className="text-login mb-5 mt-4">
                                                            <strong className="font-weight-bold font-italic">Login</strong>
                                                        </h3>
                                                        <form method={"post"} onSubmit={logarUsuario}>
                                                            <MDBInput
                                                                onChange={handleFormChange}
                                                                className="form-control text-login"
                                                                label="Seu Email"
                                                                name="email"
                                                                required
                                                                type="email"
                                                                labelClass="text-login font-weight-normal"
                                                                style={{ width: "100%" }}
                                                            />
                                                            <MDBInput
                                                                onChange={handleFormChange}
                                                                className="form-control text-login"
                                                                label="Sua Senha"
                                                                name="password"
                                                                required
                                                                type="password"
                                                                labelClass="text-login font-weight-normal"
                                                                style={{ width: "100%" }}
                                                            />
                                                            <MDBBtn type={"submit"} className="background-azul" rounded>Entrar</MDBBtn>
                                                        </form>
                                                        <p className="esqueci-minha-senha" style={{fontWeight: '800', fontStyle: 'italic'}}>
                                                            <a id="botao-cadatro" onClick={toggleParteEsqueciMinhaSenha}>Esqueci minha senha</a>
                                                        </p>
                                                        <p className="botao-registre-se">
                                                            Não tem um login?&nbsp; <a id="botao-cadatro" style={{fontWeight: '800', fontStyle: 'italic'}} onClick={toggleParteCadastro}>Cadastre-se</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>
                                    <div className="text-center">
                                        <p style={{ color: '#fff', fontWeight: "800", margin: '0', fontStyle: 'italic', textShadow: '1px 1px 2px black' }}>Baixe nosso app para seus motoristas.</p>
                                        <a href="https://expo.dev/artifacts/eas/c5rdaumM3pF2gBJfdbZJi5.apk" >
                                            <img src="https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png" width="200px" className="d-inline-block" alt="Google Play" />
                                        </a>
                                    </div>
                                </MDBContainer>
                            </div>
                        </div>
                    </div>
                </MDBMask>
            </MDBView>
        </div>
    )
}

export default Login