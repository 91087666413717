import React from 'react'
import { MDBContainer, MDBRow } from "mdbreact";
import Layout from './layout';
import "../styles/chat.css"
import ChatList from '../components/ChatList';
import ChatContainer from '../components/ChatContainer';
import { ChatProvider } from '../hooks/ChatContext';

const Chat:React.FC = () => {

  return (
    <Layout>
      <MDBContainer className="chats-container">
        <ChatProvider>
          <ChatList />
          <ChatContainer /> 
        </ChatProvider>
      </MDBContainer>

      
    </Layout>
    )

}

export default Chat;