import { useState, useEffect } from "react";
import {
    //@ts-ignore
    MDBRow,
    MDBCol,
    MDBInput,
    MDBIcon,
    MDBContainer,
    MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBView,
} from "mdbreact";
import { getDataFormatada } from "../../../services/utils";
import appApi from "../../../services/appApi";
import FormAtualizar from "../FormAtualizar";
import Loading from "../../Loading/loading";
import AtualizarStatus from "./atualizarStatus";

//@ts-ignore
const DetalhesFrete = ({ idFrete, botaoVoltar }) => {

    const [frete, setFrete] = useState<any>();

    useEffect(() => {
        appApi.get('/freights/' + idFrete).then(response => {
            setFrete(response.data);
        });
    }, []);

    return (
        <div id="detalhes-frete">
            <MDBRow className="mb-3">
                <button onClick={botaoVoltar} className="button-go-back">
                    <MDBIcon icon="arrow-left" />&nbsp;
                    Voltar
                </button>
            </MDBRow>

            {frete == null ? <Loading name='detalhes do espaço'/> :
                <section className="mt-4 mb-5">
                    <h3 className="font-weight-bold text-left mt-1 mb-3">
                        Detalhes do Espaço &nbsp;<span style={{ fontSize: "20px" }}>#{frete.id}</span>
                    </h3>
                    <strong>
                        <span>Status: <i>{frete.freightStatus.description}</i></span>    
                    </strong>        
                    <MDBRow>
                        <MDBCol lg="6">
                            <MDBContainer>
                                <h6 className="text-align-center font-weight-500 mt-2">Fotos do Veículo:</h6>
                                <MDBCarousel
                                    activeItem={1}
                                    //@ts-ignore
                                    length={frete.vehicle.pictures.length}
                                    showControls={true}
                                    showIndicators={true}
                                    className="z-depth-1"
                                >
                                    <MDBCarouselInner>
                                        {//@ts-ignore
                                        frete.vehicle.pictures.map((item) => {
                                            return (
                                            <MDBCarouselItem itemId="1">
                                                <MDBView>
                                                    <img
                                                        className="d-block w-100"
                                                        src={item.path.replace("localhost", "https://api.shareeconomy.com.br")}
                                                        alt="Primeira Foto"
                                                    />
                                                </MDBView>
                                            </MDBCarouselItem>)
                                        })}
                                    </MDBCarouselInner>
                                </MDBCarousel>
                            </MDBContainer>
                        </MDBCol>

                        <MDBCol lg="6">
                            <form method={"post"} >
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.height}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Altura disponível"
                                            name="height"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.width}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Largura disponível"
                                            name="width"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow id="inputs-mt-0">
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.depth}
                                            className="form-control color-disabled"
                                            disabled={true}
                                            label="Profundidade disponível"
                                            name="depth"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.volume}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Espaço em PBR"
                                            name="volume"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow id="inputs-mt-0">
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.price}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Preço"
                                            name="price"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.weight}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Peso"
                                            name="weight"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow id="inputs-mt-0">
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.vehicle.plate}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Veículo"
                                            name="vehicle"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.driver_id}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Motorista"
                                            name="driver"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.originState.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Estado de origem"
                                            name="originState"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.originCity.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Cidade de origem"
                                            name="originCity"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.destinationState.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Estado destino"
                                            name="destinationState"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.destinationCity.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Cidade destino"
                                            name="destinationCity"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.cargoType.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Tipo de carga"
                                            name="cargoType"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.carrierType.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Tipo de transporte"
                                            name="carrierType"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow id="inputs-mt-0">
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={getDataFormatada(frete.freight_date)}
                                            className="form-control color-disabled"
                                            label="Data de partida"
                                            disabled={true}
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            //@ts-ignore
                                            value={frete.time}
                                            className="form-control color-disabled"
                                            label="Horário de partida"
                                            disabled={true}
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                            required
                                        />
                                    </MDBCol>
                                    
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput 
                                        type="textarea" 
                                        className="form-control color-disabled"
                                        label="Descrição do Veículo" 
                                        rows="3"
                                        style={{width: "100%"}}
                                        disabled={true}
                                        //@ts-ignore
                                        value={frete.vehicle.vehicle_desc}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput 
                                            type="textarea" 
                                            className="form-control color-disabled"
                                            label="Descrição do Espaço" 
                                            rows="3"
                                            style={{width: "100%"}}
                                            disabled={true}
                                            //@ts-ignore
                                            value={frete.description}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <div className="text-center">
                                        <FormAtualizar frete={frete} handleAtualizaFrete={setFrete} />
                                    </div>

                                    <div className="text-center">
                                        <AtualizarStatus frete={frete} />
                                    </div>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </section>}
        </div>
    )
}

export default DetalhesFrete;