import React, { useState, useEffect } from 'react'
import "./styles.css"

const Paginator: React.FC<any> = ({quantity, onSelect}) => {

    const [selectedIndex, setSelectedIndex] = useState(1);
    const [buttons, setButtons] = useState("-");


    useEffect(() => {
        //@ts-ignore
        setButtons(createButtons());
    }, [quantity, selectedIndex])

    const handleSetSelectedIndex = (index: number) => {
        setSelectedIndex(index);
        onSelect(index);
    }

    const createButtons = () => {
        const temp: any = []
        for(let i = 1; i <= quantity; i++){
            temp.push(<button type="button" className={selectedIndex === i ? 'pag-index-selected' : 'pag-index'} onClick={() => handleSetSelectedIndex(i)} key={i}>{i}</button>)
        }
        return temp
    }

    return (
        <div id="paginator">
            {buttons}
        </div>
    )
}
export default Paginator;