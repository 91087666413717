import { useState, useEffect } from "react";

interface IPassword {
  firstPassword: string
  secondPassword: string
}

export const usePasswordValidation = ({ firstPassword, secondPassword }: IPassword) => {
  const [match, setMatch] = useState<boolean>(false);
    /*
      const [validLength, setValidLength] = useState(null);
      const [hasNumber, setHasNumber] = useState(null);
      const [upperCase, setUpperCase] = useState(null);
      const [lowerCase, setLowerCase] = useState(null);
      const [specialChar, setSpecialChar] = useState(null);
    */

    useEffect(() => {
      /* 
        setUpperCase(firstPassword.toLowerCase() !== firstPassword); // Caso necessite letra maiúscula
        setLowerCase(firstPassword.toUpperCase() !== firstPassword); // Caso necessite letra minúscula
        setHasNumber(/\d/.test(firstPassword)); // Caso necesside de números
        setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(firstPassword)); // Caso necessite caractere especial
      */
      setMatch(firstPassword == secondPassword);
    }, [firstPassword, secondPassword]);

    return {match, /* validLength, hasNumber, upperCase, lowerCase, specialChar */};
  
}
