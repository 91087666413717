import { TrackContextProvider } from "../services/context"
import Track from '../components/Rastreamento/index'

const Rastreamento = () => {
    return (
        <TrackContextProvider>
            <Track />
        </TrackContextProvider>
    )
}

export default Rastreamento