import { useEffect, useState } from  'react'
import Resumo from '../MeusFretes/Resumo'
import {
    //@ts-ignore
    toast,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBAnimation,
    MDBCol,
    MDBRow
} from "mdbreact";

const ListaRastreamentoCabecalho = () => {

    return (
        <>
            <MDBRow id="pagina-veiculos">
                <MDBCol md="12" lg="12" xl="12" className="col-lg-motoristas">
                    <MDBRow>
                        <MDBCol md="12" lg="10" xl="10" className="col-lg-motorista text-left">
                            <button 
                                className="waves-effect botao-adicionar-listar-motoristas font-size-17 mt-1 ml-4"
                            >
                            <h2 className="font-weight-bold color-azul"><MDBIcon icon="map-marker-alt"/> Rastrear Espaços</h2>                                    </button>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </>
    )
}

export default ListaRastreamentoCabecalho