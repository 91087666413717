import {
    createContext, 
    ReactChildren, 
    SetStateAction, 
    useState, 
    Dispatch
} from 'react'
import { IFreight } from '../types/freights'

export const TrackContext = createContext<ITrackContext>({} as ITrackContext)

interface ITrackContext {
    showDetails: boolean,
    setShowDetails: Dispatch<SetStateAction<boolean>>,
    detailsInfo: IFreight,
    setDetailsInfo: Dispatch<SetStateAction<IFreight>>
}

interface IChildren {
    children: JSX.Element
}

export const TrackContextProvider = ({ children }: IChildren) => {

    const [ showDetails, setShowDetails ] = useState<boolean>(false)
    const [ detailsInfo, setDetailsInfo ] = useState<IFreight>({} as IFreight)

    return (
        <TrackContext.Provider value={{ showDetails, setShowDetails, detailsInfo, setDetailsInfo }}>
            {children}
        </TrackContext.Provider>
    )

}

