import { useState, useEffect } from "react";
import {
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBAnimation,
    MDBCard,
    MDBInput
  } from 'mdbreact';
import { getDataFormatada } from "../../../services/utils";
import ReactInputMask from "react-input-mask";
import InputMask from 'react-input-mask';
import appApi from "../../../services/appApi";
import FormAdicionar from "../FormAdicionar";
import Paginator from "../../Paginator";
import Loading from "../../Loading/loading";
import { IFreightInfo } from "../../../types/freights";
import { IMotorista } from "../../../types/motorista.interface";

interface IProps {
    idMotorista: number
    botaoVoltar: () => void
    handleNovoMotorista: Function
}

const DetalhesMotorista = ({idMotorista, botaoVoltar, handleNovoMotorista}: IProps) => {

    const [motorista, setMotorista] = useState<IMotorista | null>(null);
    const [viagens, setViagens] = useState<IFreightInfo[]>([]);
    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        appApi.get('/drivers/'+idMotorista).then(response => {
            setMotorista(response.data);
        });

        appApi.get('/drivers/'+idMotorista+'/freights?page='+page).then(res => {
            setViagens(res.data.data);
            const qt = res.data.meta.total / res.data.meta.per_page;
            const resto = res.data.meta.total % res.data.meta.per_page;
            setQuantity(qt + (resto > 0 ? 1 : 0));
        });
    }, []);

    return (
        <MDBAnimation type="fadeIn">
            <MDBRow className="mb-3 ml-2 mt-3">
                <button onClick={botaoVoltar} className="button-go-back">
                    <MDBIcon icon="arrow-left" />&nbsp;
                        Voltar
                    </button>
            </MDBRow>

            {/* <MDBRow className="mt-2 mb-3">
                <MDBCol md="3" col="3" lg="3" xl="3">
                    <MDBCard>
                        <MDBRow className='mt-3'>
                            <MDBCol md='12' col='12' className='text-left pl-5'>
                                <h6 className='mt-2 mb-2 font-weight-bold'>Total KM rodados</h6>
                                <h3 className='mt-3 mb-4 font-weight-bold'>{motorista && motorista.meta.distance !== null ? motorista.meta.distance : "0"} </h3>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                </MDBCol> 
                
                /*
                <MDBCol md="3" col="3" lg="3" xl="3">
                    <MDBCard>
                        <MDBRow className='mt-3'>
                            <MDBCol md='12' col='12' className='text-left pl-5'>
                                <h6 className='mt-2 mb-2 font-weight-bold'>Total de viagens</h6>
                                <h3 className='mt-3 mb-4 font-weight-bold'>200 </h3>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                </MDBCol>

                <MDBCol md="3" col="3" lg="3" xl="3">
                    <MDBCard>
                        <MDBRow className='mt-3'>

                            <MDBCol md='12' col='12' className='text-left pl-5'>
                                <h6 className='mt-2 mb-2 font-weight-bold'>Multas</h6>
                                <h3 className='mt-3 mb-4 font-weight-bold'>0</h3>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                </MDBCol>

                <MDBCol md="3" col="3" lg="3" xl="3">
                    <MDBCard>
                        <MDBRow className='mt-3'>
                            <MDBCol md='12' col='12' className='text-left pl-5'>
                                <h6 className='mt-2 mb-2 font-weight-bold'>Status atual</h6>
                                <h3 className='mt-3 mb-4 font-weight-bold'>Em rota </h3>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                </MDBCol>
            </MDBRow> */}

            {!motorista ? <Loading name="detalhes do motorista"/> 
                : 
            <div id="section-listar-motoristas" className="mt-3">
                <MDBRow>
                    <MDBCol md="12">
                        <div id="form-driver">
                            <MDBRow>
                                <MDBCol md="12" lg="6" xl="6" className="mt-3 first-column-hr">
                                    <div className="ml-3 mr-3">
                                        <h3>Informações do Motorista</h3>
                                        <form method={"post"}>
                                            <MDBInput
                                                value={motorista.name}
                                                className={"form-control color-disabled"}
                                                label="Nome Completo"
                                                name="name"
                                                required
                                                type="text"
                                                labelClass={"color-disabled label-nome-completo"}
                                                disabled={true}
                                            />
                                            <div className="md-form">
                                                <label className={"color-disabled company-cnpj-mask-label"}>RG</label>
                                                <InputMask
                                                    value={motorista.rg}
                                                    className={"form-control color-disabled driver-rg-mask-input"}
                                                    mask="9999999999"
                                                    name="rg"
                                                    required
                                                    type="text"
                                                    placeholder="XXXXXXXXXX"
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="md-form">
                                                <label className={"color-disabled company-cnpj-mask-label"}>CPF</label>
                                                <InputMask
                                                    value={motorista.cpf}
                                                    className={"form-control color-disabled driver-cpf-mask-input"}
                                                    mask="999.999.999-99"
                                                    name="cpf"
                                                    required
                                                    type="text"
                                                    placeholder="XXX.XXX.XXX-XX"
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="md-form">
                                                <label className={"color-disabled company-cnpj-mask-label"}>CNH</label>
                                                <ReactInputMask
                                                    value={motorista.cnh}
                                                    className={"form-control color-disabled driver-cnh-mask-input"}
                                                    mask="99999999999"
                                                    name="cnh"
                                                    required
                                                    type="text"
                                                    placeholder="XXXXXXXXXXX"
                                                    disabled={true}
                                                />
                                            </div>
                                            <div>
                                                <MDBInput
                                                    value={getDataFormatada(motorista.expire_date)}
                                                    className={"form-control color-disabled driverexpiredateInput"}
                                                    name="expireDate"
                                                    label="Data de vencimento da CNH"
                                                    required
                                                    type="text"
                                                    aria-label="MM-DD-AAAA"
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <FormAdicionar handleNovoMotorista={handleNovoMotorista} isEdit={true} editInfo={motorista}/>
                                            </div>
                                        </form>
                                    </div>
                                </MDBCol>
                                <MDBCol md="12" lg="6" xl="6" className="mt-3">
                                    <div className="ml-3 mr-3">
                                        <h3>Histórico do Motorista</h3>
                                        <MDBTable className="mb-5 mt-3" responsive>
                                            <MDBTableHead className="font-weight-bold">
                                                <tr>
                                                    <th>Origem</th>
                                                    <th>Destino</th>
                                                    <th>Data</th>
                                                    <th>Distância</th>
                                                    <th>Veículo</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {
                                                viagens.map((item) => {
                                                    return (<tr className="table-body-hover" key={item.id}>
                                                                <td>{item.originCity.description}</td>
                                                                <td>{item.destinationCity.description}</td>
                                                                <td>{getDataFormatada(item.freight_date)}</td>
                                                                <td>{item.distance + ' km'}</td>
                                                                <td>{item.vehicle.plate}</td>
                                                            </tr>)
                                                })}
                                                
                                            </MDBTableBody>
                                        </MDBTable>
                                        <Paginator quantity={quantity} onSelect={setPage} />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
            </div>}

        </MDBAnimation>
    )

}

export default DetalhesMotorista;