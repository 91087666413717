import { useEffect, useState, useContext } from 'react'
import { TrackContext } from '../../services/context';
import Layout from '../../pages/layout'
import ListaRastreamentoCabecalho from './header';
import RastrearMeusEspacos from './meusEspacos';
import RastrearOutrosEspacos from './outrosEspacos';
import appApi from '../../services/appApi';
import Paginator from '../Paginator';
import Detalhes from './detalhes';

const Rastreamento = () => {
    
    const [ meusEspacos, setMeusEspacos ] = useState([])
    const [ outrosEspacos, setOutrosEspacos ] = useState([])
    
    const [ mineQuantity, setMineQuantity ] = useState<number>(0)
    const [ minePage, setMinePage ] = useState<number>(1)

    const [ otherQuantity, setOtherQuantity ] = useState<number>(0)
    const [ otherPage, setOtherPage ] = useState<number>(1)

    const { showDetails } = useContext(TrackContext)

    useEffect(() => {
        appApi.get('/freight_matches/me/mine?page=' + minePage).then((response) => {
            setMeusEspacos(response.data.data)
            const qt = response.data.meta.total / response.data.meta.per_page
            const resto = response.data.meta.total % response.data.meta.per_page
            setMineQuantity(qt + (resto > 0 ? 1 : 0))
        })
    }, [minePage])

    useEffect(() => {
        appApi.get('/freight_matches/me/other?page=' + otherPage).then((response) => {
            setOutrosEspacos(response.data.data)
            const qt = response.data.meta.total / response.data.meta.per_page
            const resto = response.data.meta.total % response.data.meta.per_page
            setOtherQuantity(qt + (resto > 0 ? 1 : 0))
        })
    }, [otherPage])

    return (
        <Layout>
        {
            showDetails != true ?  
            <>
                <ListaRastreamentoCabecalho />
                <RastrearMeusEspacos meusEspacos={meusEspacos} />
                <Paginator quantity={mineQuantity} onSelect={setMinePage} />
                <RastrearOutrosEspacos outrosEspacos={outrosEspacos} />
                <Paginator quantity={otherQuantity} onSelect={setOtherPage} />    
            </> 
                
                :
                
            <Detalhes />
                    
                        
        }        
        </Layout>            
    )
}

export default Rastreamento