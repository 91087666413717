import React from 'react';
import { MDBListGroup, MDBListGroupItem } from "mdbreact";
import { useChat } from '../../hooks/ChatContext';

const ChatList:React.FC = () => {

    const { 
        chats, 
        selectedChatData, 
        handleSelectedChat, 
        mine, 
        setMine,
    } = useChat();

    return (
        <div className={`chat-list ${selectedChatData !== undefined && "hide"}`}>
          <div className="chat-type-switch">
            <button onClick={() => setMine(false)} className={!mine ? 'selected' : ''}>Meus Pedidos</button>
            <button onClick={() => setMine(true)} className={mine ? 'selected' : ''}>Pedidos Recebidos</button>
          </div>
          <MDBListGroup>
            {chats !== undefined && chats.map((item: any) => { 
                return (
                    <MDBListGroupItem 
                        className={"d-flex justify-content-between align-items-center"} 
                        style={{ height: "5rem", cursor: "pointer", backgroundColor: item.id === selectedChatData?.id ? "#F7F7F7" : "#FFF"}} 
                        onClick={() => handleSelectedChat(item.id)}
                        key={item.id}>
                        <div className="chat-title"> 
                            <span className={"font-weight-bolder"}>{`${item.matchCompany.name} para ${item.freight.destinationCity.description}`}</span>
                            {item.chatMessages.length > 0 && `${item.chatMessages[item.chatMessages.length-1].text.substring(0,28)}`}
                        </div>
                    </MDBListGroupItem>
                )
            })}  
          </MDBListGroup>
        </div>
    )

}

export default ChatList;