import { isAuthenticated } from '../pages/auth';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Chat from '../pages/chat';
import Usuarios from '../pages/usuarios';
import Veiculos from '../pages/meusVeiculos';
import Dashboard from '../pages/dashboard';
import Motoristas from '../pages/meusMotoristas';
import MeusFretes from '../pages/meusFretes';
import Autenticacao from '../pages/autenticacao';
import OutrosFretes from '../pages/outrosFretes';
import MeusDados from '../pages/meus-dados';
import RedefinicaoSenha from '../pages/redefinicaoSenha';
import Rastreamento from '../pages/rastreamento';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route 
    {...rest}
    render={props => 
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/autenticacao', state: { from: props.location } }} />
      )
    }
  />
);


const Routes = () => (    

    <BrowserRouter>
        <Switch>
            <Route exact path="/autenticacao" component={Autenticacao} />
            <Route path="/autenticacao/esqueci-senha" component={RedefinicaoSenha} />

            <PrivateRoute path="/" exact component={OutrosFretes} />  
            <PrivateRoute path="/chat" exact component={Chat} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            <PrivateRoute path="/usuarios" exact component={Usuarios} />
            <PrivateRoute path="/veiculos" exact component={Veiculos} />
            <PrivateRoute path="/motoristas" exact component={Motoristas} />
            <PrivateRoute path="/meus-espacos" exact component={MeusFretes} />
            <PrivateRoute path="/rastreamento" exact component={Rastreamento} />
            <PrivateRoute path="/espacos-disponiveis" exact component={OutrosFretes} />
            <PrivateRoute path="/meus-dados" exact component={MeusDados} />
        </Switch>
    </BrowserRouter>
)

export default Routes;