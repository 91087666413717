import { useState, Dispatch, SetStateAction } from 'react';
import {
    //@ts-ignore
    toast,
    MDBCol,
    MDBBtn,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBAnimation,
  
  } from 'mdbreact';
  import appApi from '../../../services/appApi';
  import FotoVeiculo from '../../../images/truck-signup.jpg'
import { IVeiculo } from '../../../types/veiculo.interface';

interface IProps {
    listaDeVeiculos: IVeiculo[]
    handleExcluirVeiculo: Function
    setIdInfoVeiculo: Dispatch<SetStateAction<null| number>>
}

const ListaVeiculos = ({listaDeVeiculos, handleExcluirVeiculo, setIdInfoVeiculo}: IProps) => {

    const [confirmarExcluirVeiculo, setConfirmarExcluirVeiculo] = useState(false);
    const [veiculoSelecionadoParaExclusao, setVeiculoSelecionadoParaExclusao] = useState<any>(null);

    const toggleConfirmarExcluirVeiculo = (idDoVeiculo: any) => {
        setVeiculoSelecionadoParaExclusao(idDoVeiculo);
        setConfirmarExcluirVeiculo(!confirmarExcluirVeiculo);
    }

    const ExcluirVeiculo = (event: any) => {
        event.preventDefault();
    
        if (!veiculoSelecionadoParaExclusao) {
          return;
        }
    
        appApi.delete(`/vehicles/${veiculoSelecionadoParaExclusao}`).then((response) => {
            setConfirmarExcluirVeiculo(false);
            toast.success(<span><MDBIcon icon="check" /> &nbsp; Veículo excluído com êxito!</span>, {
            closeButton: false,
            autoclose: 5000
          });
    
        setVeiculoSelecionadoParaExclusao(null);
        handleExcluirVeiculo(veiculoSelecionadoParaExclusao);
    
        })
        .catch((error) => {
            if(error.response.status === 422){
                toast.error(<span><MDBIcon icon="times"/> &nbsp; Este veículo não pode ser excluído pois possui espaços vinculados.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            } else {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte.</span>, {
                    closeButton: false,
                    autoclose: 5000
                })
            }            
        });
    }

    return (
        <>
            <MDBAnimation type="fadeIn">
                <div id="section-listar-veiculos">
                <MDBCol md="12" className="mt-2 pl-0 pr-0">
                    <MDBTable className="mb-5" responsive>
                    <MDBTableHead color="background-azul" textWhite>
                        <tr>
                        <th>ID</th>
                        <th>Placa</th>
                        <th>Renavam</th>
                        <th>Informações</th>
                        <th style={{paddingLeft: "35px"}}>Ações</th>
                        <th className="text-center">Veículo</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {listaDeVeiculos.length > 0 && listaDeVeiculos.map((veiculo: IVeiculo) => {
                        return (
                            <tr className="table-body-hover" id={"veiculo" + veiculo.id} key={veiculo.id}>
                                <td>{veiculo.id}</td>
                                <td className="text-uppercase">{veiculo.plate}</td>
                                <td>{veiculo.renavam}</td>
                                <td>
                                    <a onClick={() => setIdInfoVeiculo(veiculo.id)}>
                                    <MDBIcon icon="info" className="d-inline-inline padding-10" />
                                    Info
                                </a>
                                </td>
                                <td>
                                    <a onClick={() => toggleConfirmarExcluirVeiculo(veiculo.id)}>
                                        <MDBIcon icon="times" className="d-inline-inline padding-10 text-danger" />
                                        Excluir
                                    </a>
                                </td>
                                <td className="text-center">
                                    <img className="img-fluid" width="80" height="50" src={veiculo.pictures.length > 0 ? veiculo.pictures[0].path.replace("localhost", "https://api.shareeconomy.com.br") : FotoVeiculo} alt="Foto Veículo" />
                                </td>
                            </tr>
                        );
                        })}
                    </MDBTableBody>
                    </MDBTable>
                </MDBCol>
                </div>
            </MDBAnimation>
            <div id="modal-excluir-veiculo">
                <MDBModal id="excluir-motorista" isOpen={confirmarExcluirVeiculo} centered className="border-radius-adicionar-motorista">
                <MDBModalHeader toggle={() => toggleConfirmarExcluirVeiculo(null)} className="background-azul white-text modal-header-h4 text-align-center d-inline-block">
                    Tem certeza que deseja excluir o veículo?
                </MDBModalHeader>
                <MDBModalBody className="margins-laterais-15 text-align-center">
                    <p>
                        <MDBIcon icon="exclamation-triangle" style={{color: "#FFBB33"}} />&nbsp; 
                        Esta ação <strong>não</strong> poderá ser desfeita!
                    </p>
                    {/*@ts-ignore*/}
                    <MDBBtn onClick={ExcluirVeiculo} id="btn-excluir-veiculo" size="md" type={"submit"} className="background-azul btn white-text">
                        <MDBIcon icon="check" />&nbsp;
                        Confirmar
                    </MDBBtn>
                    {/*@ts-ignore*/}
                        <MDBBtn onClick={() => toggleConfirmarExcluirVeiculo(null)} id="btn-cancelar-exclusao-veiculo" size="md" type={"submit"} className="background-azul btn white-text">
                    <MDBIcon icon="times" />&nbsp;
                    Cancelar
                    </MDBBtn>
                </MDBModalBody>
                </MDBModal>
            </div>
        </>
    )

}

export default ListaVeiculos;