import React, { useState, useRef, useEffect }  from 'react';
import { MDBIcon } from "mdbreact";
import { useChat } from '../../hooks/ChatContext';


const ChatContainer:React.FC = () => {
    const { selectedChatData, sendMessage, mine } = useChat();
    
    const scrollableContainer = useRef<any>(null);

    const [text, setText] = useState<any>("");

    const handleSendMessage = (e: any) => {
      e.preventDefault();
      if(text !== ""){
        sendMessage(text);
        setText("");
      }
    }

    const scrollToBottom = () => {
      scrollableContainer.current?.scrollTo(0, scrollableContainer.current?.scrollHeight);
    }

    useEffect(() => {
      scrollToBottom();
    }, [selectedChatData])


    return (
        <div className={`chat-container ${selectedChatData === undefined && "hide"}`}>
        {selectedChatData !== undefined ? (
          <>
            <div className="chat-name">{`${selectedChatData.matchCompany.name} para ${selectedChatData.freight.destinationCity.description}`}</div>
            <div className="messages-container" ref={scrollableContainer}>
              <div className="messages-section">
              {selectedChatData.chatMessages.length > 0 ? selectedChatData.chatMessages.map((item: any, index: number) => {
                return (
                  <div className="message" key={index}>
                    <div className={`message-bubble ${((item.company_id === selectedChatData.proposal_company_id && mine) || (!mine && item.company_id === selectedChatData.match_company_id)) && "bubble-user"}`}>{item.text}</div>
                  </div>   
                )
              }) : (
                <div className="message">
                    <div className="message-bubble message-empty-chat">Nenhuma mensagem foi enviada ainda!</div>
                </div>
              )}
              </div>
              <div />
            </div>
            <form onSubmit={(e) => handleSendMessage(e)} className="input-section">
              <input type="text" value={text} placeholder="Digite algo" onChange={(e) => setText(e.target.value)}/>
              <button type="submit"><MDBIcon icon="arrow-right"/></button>
            </form>
          </>
        ) : (
         <>
           <h4>Selecione um chat ao lado para começar!</h4>
         </>
        )}
        </div>
    )

}

export default ChatContainer;