import { useState, useEffect } from 'react';
import {
    //@ts-ignore
    toast,
    MDBIcon,
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBAnimation,
} from "mdbreact";
import { getDataFormatada } from "../../../services/utils";
import { IFreight } from '../../../types/freights';
import appApi from '../../../services/appApi';

//@ts-ignore
const ListaFretes = ({listaDeFretes, handleExcluirFrete, setIdInfoFrete}) => {

    const [confirmarExcluirFrete, setConfirmarExcluirFrete] = useState<boolean>(false);
    const [freteParaExclusao, setFreteParaExclusao] = useState<number | null>(0);


    const toggleConfirmarExclusaoFrete = (idFrete: number | null) => {
        return function(){
            setFreteParaExclusao(idFrete);
            setConfirmarExcluirFrete(confirmarExcluirFrete ? false : true);
        } 
    }

    const ExcluirFrete = (event: any) => {
        event.preventDefault();

        if (!freteParaExclusao) {
            return;
        }

        appApi.delete(`/freights/${freteParaExclusao}`).then((response) => {
            if(response.status == 204){
                setConfirmarExcluirFrete(false);
                    toast.success(<span><MDBIcon icon="check" /> &nbsp; Espaço excluído com êxito!</span>, {
                        closeButton: false,
                        autoclose: 5000
                    });
                    
                    toggleConfirmarExclusaoFrete(null);
                    handleExcluirFrete(freteParaExclusao);

                    appApi.delete(`/freight_matches/${freteParaExclusao}`)
            }
            
        })
        .catch((error) => {
            if(error.response.status === 400){
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Espaço não está em aberto.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            } else {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            }
        })
    }

    return (
        <>
            <MDBAnimation type="fadeIn" style={{marginTop: "30px"}}>
                <MDBCard>
                    <MDBCardBody>
                        <MDBTable className="mb-4" responsive>
                            <MDBTableHead color="background-azul" textWhite>
                                <tr>
                                    <th>ID</th>
                                    <th>Origem</th>
                                    <th>Destino</th>
                                    <th>Data e Horário</th>
                                    <th>Natureza</th>
                                    <th className="text-center">Veículo</th>
                                    <th>Ações</th>
                                    <th>Atualizar</th>
                                    <th>Status</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {
                                listaDeFretes.length > 0 && listaDeFretes.map((frete: any) => {
                                    return (
                                        <tr className="table-body-hover" key={frete.id}>
                                            <td>{frete.id}</td>
                                            <td>{frete.originCity.description}</td>
                                            <td>{frete.destinationCity.description}</td>
                                            <td>{getDataFormatada(frete.freight_date)} - {frete.time}</td>
                                            <td>{frete.cargoType.description}</td>
                                            <td className="text-center">
                                                {frete.vehicle.plate}
                                            </td>
                                            <td>
                                                <a onClick={toggleConfirmarExclusaoFrete(frete.id)}>
                                                    <MDBIcon icon="times" className="d-inline-inline -10 text-danger" />
                                                    &nbsp; Excluir
                                                </a>
                                            </td>
                                            <td>
                                                <a onClick={() => setIdInfoFrete(frete.id)}>
                                                    <MDBIcon icon="info" className="d-inline-inline -10 text-blue" />
                                                    &nbsp; Info
                                                </a>
                                            </td>
                                            <td>
                                                <a><strong>{frete.freightStatus.description}</strong></a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            </MDBAnimation>
            <MDBModal id="excluir-frete" isOpen={confirmarExcluirFrete} centered className="border-radius-adicionar-motorista">
                <MDBModalHeader onClick={toggleConfirmarExclusaoFrete(null)} className="background-azul white-text modal-header-h4 text-align-center d-inline-block">
                    Tem certeza que deseja excluir o Espaço ?
                </MDBModalHeader>
                <MDBModalBody className="margins-laterais-15 text-align-center">
                    <p>
                        <MDBIcon icon="exclamation-triangle" style={{ color: "#FFBB33" }} />&nbsp;
                        Esta ação <strong>apagará as mensagens</strong> e não poderá ser desfeita!
                    </p>
                    {/* @ts-ignore */}
                    <MDBBtn onClick={ExcluirFrete} id="btn-excluir-veiculo" size="md" type={"submit"} className="background-azul btn white-text">
                        <MDBIcon icon="check" />&nbsp;
                        Confirmar
                    </MDBBtn>
                    {/* @ts-ignore */}
                    <MDBBtn onClick={toggleConfirmarExclusaoFrete(null)} id="btn-cancelar-exclusao-veiculo" size="md" type={"submit"} className="background-azul btn white-text">
                        <MDBIcon icon="times" />&nbsp;
                        Cancelar
                    </MDBBtn>
                </MDBModalBody>
            </MDBModal>
        </>
    )
}

export default ListaFretes;