//@ts-nocheck
import { useState, useEffect } from "react";
import {
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBtn,
    MDBContainer,
    MDBInput,
    ToastContainer,
    toast
} from "mdbreact";
import { useHistory } from "react-router-dom";
import { getDataFormatada, getTelefoneFormatado } from "../../../services/utils";
import appApi from "../../../services/appApi";
import ImageGallery from "react-image-gallery"
import Loading from "../../Loading/loading";

const DetalhesFrete = ({idFrete, botaoVoltar}) => {

    const [frete, setFrete] = useState(null); 
    let history = useHistory();
    
    useEffect(() => {
        appApi.get('/freights/'+idFrete).then(response => {
            setFrete(response.data);
        });
    }, []);

    const entraNoChat = () => {
        appApi.post('/freight_matches', { freightId: idFrete }).then((response) => {
            if (response.status === 200 || response.status === 204) {
                history.push("/chat");
            }
        }).catch((error) => {
            if(error.response.status == 400){
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Espaço pertence à sua empresa.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            }
        })
    }

    return (
        <>
            <ToastContainer
                hideProgressBar
                newestOnTop
                autoClose={5000}
            />
            <div>
            <MDBRow className="mb-3 ml-3">
                <button onClick={botaoVoltar} className="button-go-back">
                    <MDBIcon icon="arrow-left" />&nbsp;
                        Voltar
                    </button>
            </MDBRow>

            {frete == null ? <Loading /> :
            <section className="mt-4 mb-5">
                <h3 className=" font-weight-bold text-left mt-1 mb-3 ml-3">
                    Detalhes do Espaço
                </h3>
                <MDBRow>
                    <MDBCol lg="6">
                        <MDBContainer>
                            <h6 className="text-align-center font-weight-500 mt-2">Fotos Veículo:</h6>
                            <ImageGallery
                                items={frete.vehicle.pictures.map((item) => {
                                    return {original: item.path, thumbnail: item.path}
                                })}
                            />
                        </MDBContainer>
                        <MDBRow className="mt-4 mb-4">
                            {/*<MDBCol md="4" className="column-4-mobile">
                                <div className="z-depth-1 mb-4 overlay rounded border-radius-7">
                                    <img
                                        //className="img-fluid border-radius-7"
                                        //src={frete.company.picture.path.replace("localhost", "191.252.210.144")}
                                        alt=""
                                    />
                                </div>
                            </MDBCol>*/}
                            <MDBCol md="8" className="column-6-mobile">
                                <p className="font-weight-bold dark-grey-text mt-3 ml-4 ">
                                    {frete.company.name} &nbsp;
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>

                    <MDBCol lg="6" className="mt-4">
                        
                        <MDBRow id="detalhes-frete-row">
                            <MDBCol md="1" xl="1" className="text-center column-2-mobile">
                                <MDBIcon size="2x" far icon="calendar-alt" />
                            </MDBCol>
                            <MDBCol md="11" xl="4" className="detalhes-frete-lista column-10-mobile">Data: {getDataFormatada(frete.freight_date)}</MDBCol>

                             <MDBCol md="1" xl="1" className="text-center column-2-mobile">
                                <MDBIcon size="2x" far icon="clock" />
                            </MDBCol>
                            <MDBCol md="11" xl="6" className="detalhes-frete-lista column-10-mobile">Horário: {frete.time}</MDBCol>
                            
                        </MDBRow>
                        <MDBRow id="detalhes-frete-row">
                            <MDBCol md="1" xl="1" className="text-center column-2-mobile">
                                <MDBIcon size="2x" icon="dollar-sign" />
                            </MDBCol>
                            <MDBCol md="11" xl="4" className="detalhes-frete-lista column-10-mobile">Preço: {"R$ "+frete.price}</MDBCol>

                            <MDBCol md="1" xl="1" className="text-center column-2-mobile">
                                <MDBIcon size="2x" icon="phone" />
                            </MDBCol>
                            <MDBCol md="11" xl="6" className="detalhes-frete-lista column-10-mobile">Contato: {getTelefoneFormatado(frete.company.phone)}</MDBCol>

                            
                        </MDBRow>
                        <MDBRow className="row-origem-destino mt-4 mb-4">
                            <MDBCol md="4" lg="4" xl="4" className="text-center margin-top-5">
                                <strong>Origem:</strong> {frete.originCity.description + " / " + frete.originState.description}
                            </MDBCol>
                            <MDBCol md="2" lg="2" xl="2" className="text-center">
                                <MDBIcon size="2x" icon="long-arrow-alt-right" id="icon-arrow-desktop" />
                                <MDBIcon size="2x" icon="long-arrow-alt-down" id="icon-arrow-mobile" />
                            </MDBCol>
                            <MDBCol md="4" lg="4" xl="4" className="text-center margin-top-5">
                                <strong>Destino:</strong> {frete.destinationCity.description + " / " + frete.destinationState.description}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2 mb-3">
                            <MDBCol md="1" xl="1" className="text-center column-2-mobile">
                                <MDBIcon size="2x" icon="truck-moving" />
                            </MDBCol>
                            <MDBCol md="11" xl="11" className="detalhes-frete-lista column-10-mobile">Informações</MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="6" size="6">
                                <h6 className="font-weight-500">
                                    Natureza: &nbsp;
                                    <span className="text-login-black font-size-17">{frete.cargoType.description}</span>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12" size="12">
                                <h6 className="font-weight-500">
                                    Tipo de Veículo: &nbsp;
                                    <span className="text-login-black font-size-17">{frete.vehicle.vehicleType.description}</span>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12" size="12">
                                <h6 className="font-weight-500">
                                    Altura Disponível: &nbsp;
                                    <span className="text-login-black font-size-17">{frete.height} metros</span>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12" size="12">
                                <h6 className="font-weight-500">
                                    Largura Disponível: &nbsp;
                                    <span className="text-login-black font-size-17">{frete.width} metros</span>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12" size="12">
                                <h6 className="font-weight-500">
                                    Profundidade Disponível: &nbsp;
                                    <span className="text-login-black font-size-17">{frete.depth} metros</span>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12" size="12">
                                <h6 className="font-weight-500">
                                    Peso: &nbsp;
                                    <span className="text-login-black font-size-17">{frete.weight} KGs</span>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12" size="12">
                                <h6 className="font-weight-500">
                                    Observações do Espaço: &nbsp;
                                    <span className="text-login-black font-size-17"><i>{frete.description}</i></span>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12" size="12">
                                <h6 className="font-weight-500">
                                    Observações do Veículo: &nbsp;
                                    <span className="text-login-black font-size-17"><i>{frete.vehicle.vehicle_desc}</i></span>
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="">
                            <MDBCol sm="12" md="12" lg="4" xl="4" size="3">
                                <MDBBtn onClick={entraNoChat} size="md" id="btn-cadastro-motorista" className="w-max-content text-left">
                                    <MDBIcon icon="comment" />&nbsp;&nbsp;
                                    Chat
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </section>}
        </div>
        </>
        
    )

}

export default DetalhesFrete;