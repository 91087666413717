import moment from 'moment'
import {
    MDBListGroup, 
    MDBListGroupItem,
    MDBIcon,
    MDBBtn
} from 'mdbreact'
import { useEffect, useState } from 'react'

interface Location {
    id: number
    address: string,
    created_at: Date
}

interface IProps {
    locationList: Location[],
}

type LocationContainerProps = {location: Location}

const LocationContainer = ({ location }: LocationContainerProps) => {
    return (
        <MDBListGroup style={{marginBottom: '20px'}} key={location.id}>
            <MDBListGroupItem><MDBIcon icon='clock' style={{marginRight: '10px'}} />{moment(location.created_at).format('D/MM/YYYY, hh:mm')}</MDBListGroupItem>
            <MDBListGroupItem><MDBIcon icon='location-arrow' style={{marginRight: '10px'}} />{location.address}</MDBListGroupItem>
        </MDBListGroup>
    )
}

const ListaTrajeto = ({ locationList }: IProps) => {

    const [ shortList, setShortList ] = useState([])

    const [ allList, setAllList ] = useState<boolean>(false)

    useEffect(() => {
        const list: any = []
        if(locationList.length > 0){
            for(let i = 0; i < 3; i++){
                list.push(
                    <MDBListGroup style={{marginBottom: '20px'}} key={locationList[i].id}>
                        <MDBListGroupItem><MDBIcon icon='clock' style={{marginRight: '10px'}} />{moment(locationList[i].created_at).format('D/MM/YYYY, hh:mm')}</MDBListGroupItem>
                        <MDBListGroupItem><MDBIcon icon='location-arrow' style={{marginRight: '10px'}} />{locationList[i].address}</MDBListGroupItem>
                    </MDBListGroup>
                )
            }
        }
        setShortList(list)
    }, [locationList])



    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h2><strong>Localização</strong></h2>
                <MDBBtn size='sm' color='blue' className='lista-trajetos-btn' onClick={() => setAllList(!allList)}>{allList ? 'Mostrar menos' : 'Mostrar mais'} </MDBBtn>
            </div>

            { 
               
                allList ? 
                    <>
                        {locationList.map((location) => {
                            return (
                                <LocationContainer location={location} />
                            )
                        })}
                    </>    
                : 
                   <>{shortList}</>
            }
        </div>
    )
}

export default ListaTrajeto