//@ts-nocheck
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBSideNavNav,
  MDBSideNav,
  MDBContainer,
} from 'mdbreact';
import { useState, useEffect, useCallback } from 'react';
import logoColorida from '../../images/logoColorida.svg';
import fundoTruck from '../../images/truck-nav.jpg';
import { logout } from '../../pages/auth';
import SideNavItem from './navItem'

const Index = (props) => {

  if (typeof window === 'undefined') {
    global.window = {};
  }

  const [toggleStateA, setToggleStateA] = useState(false);
  const [breakWidth] = useState(1300);
  const [windowWidth, setWindowWidth] = useState(0);

  const handleResize = useCallback(
    () => setWindowWidth(window.innerWidth),
    [],
  );

  const handleToggleClickA = useCallback(
    () => setToggleStateA(!toggleStateA),
    [toggleStateA],
  );

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navStyle = {
    paddingLeft: windowWidth > breakWidth ? '210px' : '16px',
  };
  const mainStyle = {
    margin: '0 0 6% 0',
    paddingTop: '5.5rem',
    paddingLeft: windowWidth > breakWidth ? '240px' : '0',
  };
  const specialCaseNavbarStyles = {
    WebkitBoxOrient: 'horizontal',
    flexDirection: 'row',
  };

  return (
    <div className="">
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.11.2/css/all.css" />
      <div className="fixed-sn white-skin">
        <MDBSideNav
          logo={logoColorida}
          triggerOpening={toggleStateA}
          breakWidth={breakWidth}
          bg={fundoTruck}
          mask="strong"
          fixed
          href="/"
        >
          <MDBSideNavNav>
            <SideNavItem 
              name='Comprar Espaços em Veículos'
              route='/espacos-disponiveis'
              iconName='money-bill-alt'
            />
            <SideNavItem 
              name='Meus Espaços'
              route='/meus-espacos'
              iconName='truck-loading'
            />
            <SideNavItem 
              name='Rastreamento de Veículos'
              route='/rastreamento'
              iconName='map-marker-alt'
            />
            <SideNavItem 
              name='Veículos'
              route='/veiculos'
              iconName='truck-moving'
            />
            <SideNavItem 
              name='Motoristas'
              route='/motoristas'
              iconName='id-card'
            />
            <SideNavItem 
              name='Chat'
              route='/chat'
              iconName='comments'
            />
          </MDBSideNavNav>
        </MDBSideNav>
        <MDBNavbar style={navStyle} className="background-azul"double expand="md" fixed="top" scrolling>
          <MDBNavbarNav left>
            <MDBNavItem>
              <div
                onClick={handleToggleClickA}
                key="sideNavToggleA"
                style={{
                  lineHeight: '32px',
                  marginRight: '1em',
                  verticalAlign: 'middle',
                }}
              >
                <MDBIcon icon="bars" color="white" className="white-text" size="2x" />
              </div>
            </MDBNavItem>
            <MDBNavItem
              className="d-none d-md-inline"
              style={{ paddingTop: 5, }}
            />
          </MDBNavbarNav>
          <MDBNavbarNav right style={specialCaseNavbarStyles}>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret className="white-text">
                  <MDBIcon icon="user" className="d-inline-inline white-text" />
                  {' '}
                  <div className="d-none d-md-inline white-text">Perfil</div>
                </MDBDropdownToggle>
                <MDBDropdownMenu right>
                  <MDBDropdownItem href="/meus-dados"><MDBIcon icon="address-book"/>&nbsp; Meus Dados</MDBDropdownItem>
                  <MDBDropdownItem onClick={logout} href="/autenticacao"><MDBIcon icon="sign-out-alt" /> Sair</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBNavbar>
        <main id="background-all-pages" style={mainStyle}>
          <MDBContainer style={{ padding: '0 6%' }} fluid className="mt-4 mb-4">
            {props.children}
          </MDBContainer>
        </main>
      </div>
    </div>
  );
};

export default Index;
