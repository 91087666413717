import {
    //@ts-ignore
    toast,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBAnimation,
    MDBCol,
    MDBRow
} from "mdbreact";
import { useContext } from "react";
import { getDataFormatada } from '../../services/utils';
import { TrackContext } from '../../services/context';


const RastrearOutrosEspacos = ({ outrosEspacos }: any) => {

    const { setShowDetails, setDetailsInfo } = useContext(TrackContext)

    const handleDetails = (espaco: any) => {
        setShowDetails(true)
        setDetailsInfo(espaco)
    }

    return (
        <>
        <MDBRow style={{marginTop: '30px'}}>
            <MDBCol md="12" lg="12" xl="12" className="col-lg-motorista text-left">
                <button 
                        className="waves-effect botao-adicionar-listar-motoristas font-size-17 mt-1 ml-4"
                    >
                    <h3 className="font-weight-bold color-azul">Outros Espaços</h3>
                </button>
            </MDBCol>             
        </MDBRow>
        <MDBAnimation type="fadeIn">
            <MDBCard>
                <MDBCardBody>
                    <MDBTable className="mb-4" responsive>
                        <MDBTableHead color="background-azul" textWhite>
                            <tr>
                                <th className="text-center">ID</th>
                                <th className="text-center">Origem</th>
                                <th className="text-center">Destino</th>
                                <th className="text-center">Data e Horário</th>
                                <th className="text-center">Veículo</th>
                                <th className="text-center">Detalhes</th>
                                <th className="text-center">Status</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {outrosEspacos.map((espaco: any) => (
                                <tr key={espaco.id}>
                                    <td className='text-center'>{espaco.freight.id}</td>
                                    <td className='text-center'>{espaco.freight.originCity.description} - {espaco.freight.originState.description}</td>
                                    <td className='text-center'>{espaco.freight.destinationCity.description} - {espaco.freight.destinationState.description}</td>
                                    <td className='text-center'>{getDataFormatada(espaco.freight.freight_date)} - {espaco.freight.time}</td>
                                    <td className='text-center'>{espaco.freight.vehicle.plate}</td>
                                    <td className='text-center' onClick={() => handleDetails(espaco)}>
                                        <a className="padding-10">
                                            <MDBIcon icon="eye" className="color-azul" />&nbsp;
                                        </a>
                                    </td>
                                    <td className='text-center'><strong>{espaco.freight.freightStatus.description}</strong></td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCardBody>
            </MDBCard>
        </MDBAnimation>
        </>
    )
}

export default RastrearOutrosEspacos