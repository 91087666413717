import { useState } from "react";
import {
    //@ts-ignore
    toast,
    MDBCol,
    MDBBtn,
    MDBIcon,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBInput
  } from 'mdbreact';
  import { getDataForm } from "../../../services/utils";
  import InputMask from 'react-input-mask';
  import appApi from "../../../services/appApi";

  //@ts-ignore
  const FormAdicionar = ({handleNovoMotorista, isEdit = false, editInfo = {}}) => {

    const [modalAdicionaMotorista, setModalAdicionaMotorista] = useState(false);
    const [formDataDriver, setFormDataDriver] = useState<any>(editInfo);


    const toggleModalAdicionaMotorista = () => {
        setModalAdicionaMotorista(modalAdicionaMotorista ? false : true);
    }

    const handleFormChange = (event: any) => {
        const inputName = event.target.name;
        const newFormDataDriver = Object.assign({}, formDataDriver)
        newFormDataDriver[inputName] = event.target.value;
        setFormDataDriver(newFormDataDriver);
    }

    //@ts-ignore
    const CadastrarNovoMotorista = (event) => {
        event.preventDefault();

        formDataDriver.cpf = formDataDriver.cpf.replace(/\D/, '').replace(/\./, '').replace(/\-/, '');
        formDataDriver.expireDate = formDataDriver.expireDate.replace(/\-/, '/').replace(/\-/, '/');

        if(!isEdit){
            appApi.post("/drivers", formDataDriver).then((response) => {
                setModalAdicionaMotorista(false);
                handleNovoMotorista(response.data);
                toast.success(<span><MDBIcon icon="check" /> &nbsp; Motorista cadastrado com sucesso!</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
                setFormDataDriver({
                    name: '',
                    rg: '',
                    cpf: '',
                    cnh: '',
                    expireDate: ''
                });
            })
            .catch((error) => {
                if (error.status === 500) {
                    toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte.</span>, {
                        closeButton: false,
                        autoclose: 5000
                    });
                    return;
                }
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro em algum campo do Formulário.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            });
        } else {
            //@ts-ignore
            appApi.put("/drivers/"+editInfo.id, formDataDriver).then((response) => {
                setModalAdicionaMotorista(false);
                handleNovoMotorista(response.data);
                toast.success(<span><MDBIcon icon="check" /> &nbsp; Motorista atualizado com sucesso!</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
                setFormDataDriver({
                    name: '',
                    rg: '',
                    cpf: '',
                    cnh: '',
                    expireDate: ''
                });
            })
            .catch((error) => {
                if (error.status === 500) {
                    toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte.</span>, {
                        closeButton: false,
                        autoclose: 5000
                    });
                    return;
                }
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro em algum campo do Formulário.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            });
        }
    }

    return (
        <>
            {!isEdit ? (
                <MDBCol md="12" lg="2" xl="2">
                    <span className="botao-adicionar-novo-motorista">
                        <button onClick={toggleModalAdicionaMotorista} className="waves-effect border-radius-7 w-100">
                            Adicionar
                        </button>
                    </span>
                </MDBCol>
            ) : (
                <MDBBtn onClick={toggleModalAdicionaMotorista} id="btn-cadastro-motorista" size="sm" className="background-azul btn white-text">
                    Atualizar
                </MDBBtn>
            )}
            <MDBModal id="cadastrar-motorista" isOpen={modalAdicionaMotorista} centered className="border-radius-adicionar-motorista">
                <MDBModalHeader toggle={toggleModalAdicionaMotorista} className="background-azul white-text modal-header-h4">
                    {!isEdit ? 'Cadastrar Motorista' : 'Editar Motorista'}
                </MDBModalHeader>
                <MDBModalBody className="margins-laterais-15">
                    <form method={"post"} onSubmit={CadastrarNovoMotorista}>
                        <MDBInput
                            onChange={handleFormChange}
                            value={formDataDriver.name}
                            className="form-control text-login-black"
                            label="Nome Completo"
                            name="name"
                            required
                            type="text"
                            labelClass="text-login-black label-nome-completo"
                        />
                        <div className="md-form">
                            <label className="text-login-black company-cnpj-mask-label">RG</label>
                            <InputMask
                                onChange={handleFormChange}
                                value={formDataDriver.rg}
                                className="form-control text-login-black driver-rg-mask-input"
                                mask="9999999999"
                                name="rg"
                                required
                                type="text"
                                placeholder="XXXXXXXXXX"
                            />
                        </div>
                        <div className="md-form">
                            <label className="text-login-black company-cnpj-mask-label">CPF</label>
                            <InputMask
                                onChange={handleFormChange}
                                value={formDataDriver.cpf}
                                className="form-control text-login-black driver-cpf-mask-input"
                                mask="999.999.999-99"
                                name="cpf"
                                required
                                type="text"
                                placeholder="XXX.XXX.XXX-XX"
                            />
                        </div>
                        <div className="md-form">
                            <label className="text-login-black company-cnpj-mask-label">CNH</label>
                            <InputMask
                                onChange={handleFormChange}
                                value={formDataDriver.cnh}
                                className="form-control text-login-black driver-cnh-mask-input"
                                mask="99999999999"
                                name="cnh"
                                required
                                type="text"
                                placeholder="XXXXXXXXXXX"
                            />
                        </div>
                        <div>
                            <MDBInput
                                onChange={handleFormChange}
                                
                                className="form-control text-login-black driverexpiredateInput"
                                label="Data de Vencimento da CNH"
                                name="expireDate"
                                required
                                type="date"
                                aria-label="MM-DD-AAAA"
                                labelClass="text-login-black driver-expire-date-Label"
                            />
                        </div>
                        <div className="text-right">
                            <MDBBtn id="btn-cadastro-motorista" size="sm" type={"submit"} className="background-azul btn white-text">
                                Confirmar
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </MDBModal>
        </>
    )

  }

  export default FormAdicionar;