import { useState } from 'react';
import {
    //@ts-ignore
    toast,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBIcon,
    MDBBtn,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
} from "mdbreact";
import appApi from '../../../services/appApi';

//@ts-ignore
const FormAdicionar = ({handleNovoFrete}) => {

    const [adicionaFrete, setAdicionaFrete] = useState(false);
    const [todosEstados, setTodosEstados] = useState([]);
    const [todosMotoristas, setTodosMotoristas] = useState([]);
    const [todosVeiculos, setTodosVeiculos] = useState([]);
    const [formDataFrete, setFormDataFrete] = useState({});
    const [estadoChegada, setEstadoChegada] = useState(null);
    const [estadoSaida, setEstadoSaida] = useState(null);
    const [tipoCarga, setTipoCarga] = useState(null);
    const [tipoTransporte, setTipoTransporte] = useState(null)

    const toggleAdicionaFrete = () => {
        setAdicionaFrete(!adicionaFrete);
        if(!adicionaFrete){
            appApi.get('/states').then(res => {
                setTodosEstados(res.data.data);
             });
            appApi.get('/drivers').then((response) => {
                setTodosMotoristas(response.data.data);
            });
            appApi.get('/vehicles').then((response) => {
                setTodosVeiculos(response.data.data);
            });
            appApi.get('/cargo_types').then((response)=>{
                setTipoCarga(response.data.data)
            })
            appApi.get('/carrier_types').then((response)=>{
                setTipoTransporte(response.data)
            })
        }
    }

    //@ts-ignore
    const handleFormChange = (event) => {
        const inputName = event.target.name;
        //@ts-ignore
        formDataFrete[inputName] = event.target.value;
        setFormDataFrete(formDataFrete);
    }

    //@ts-ignore
    const CadastrarFrete = (event) => {
        event.preventDefault();

        //@ts-ignore
        appApi.post("/freights", {...formDataFrete, freightDate: formDataFrete.freightDate + " 00:00:00"}).then((response) => {
            toast.success(<span><MDBIcon icon="check" /> &nbsp; Espaço cadastrado com sucesso!</span>, {
                closeButton: false,
                autoclose: 5000
            });
            handleNovoFrete(response.data);
            toggleAdicionaFrete();
        })
        .catch((error) => {
            if (error.status === 500) {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
                return;
            }
            toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro em algum campo do Formulário.</span>, {
                closeButton: false,
                autoclose: 5000
            });
        })
    }

    return (
        <>
            <MDBCol md="12" lg="2" xl="2" className="col-lg-motorista">
                <span className="botao-adicionar-novo-motorista">
                    <button 
                        className="waves-effect border-radius-7 w-100"  
                        onClick={toggleAdicionaFrete} 
                    >
                        Adicionar
                    </button>
                </span>
            </MDBCol>
            <div id="modalAdicionarFrete">
                <MDBModal isOpen={adicionaFrete} centered className="border-radius-adicionar-motorista">
                    <MDBModalHeader toggle={toggleAdicionaFrete} className="background-azul white-text modal-header-h4">
                        Adicionar Espaço
                    </MDBModalHeader>
                    <MDBModalBody className="margin-laterais-15">
                        <form method={"post"} onSubmit={CadastrarFrete}>
                            <MDBRow>
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        value={formDataFrete.height}
                                        className="form-control text-login"
                                        label="Altura disponível"
                                        name="height"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        value={formDataFrete.width}
                                        className="form-control text-login"
                                        label="Largura disponível"
                                        name="width"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow id="inputs-mt-0">
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        value={formDataFrete.depth}
                                        className="form-control text-login"
                                        label="Profundidade disponível"
                                        name="depth"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        value={formDataFrete.volume}
                                        className="form-control text-login"
                                        label="Espaço em PBR"
                                        name="volume"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow id="inputs-mt-0">
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        value={formDataFrete.price}
                                        className="form-control text-login"
                                        label="Preço"
                                        name="price"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        value={formDataFrete.weight}
                                        className="form-control text-login"
                                        label="Peso"
                                        name="weight"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow id="inputs-mt-0">
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        
                                        name="vehicleId"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Veículo</option>
                                        {todosVeiculos.map((veiculo) => (
                                            //@ts-ignore
                                            <option className="text-login-black" value={veiculo.id}>{veiculo.plate}</option>
                                        ))}
                                    </select>
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        
                                        name="driverId"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Motorista</option>
                                        {todosMotoristas.map((motorista) => (
                                            //@ts-ignore
                                            <option key={motorista.id} className="text-login-black" value={motorista.id}>{motorista.name}</option>
                                        ))}
                                    </select>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={(e) => {
                                            handleFormChange(e);
                                            //@ts-ignore
                                            setEstadoSaida(e.target.value);
                                        }}
                                       
                                        name="originStateId"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Estado de Saída</option>
                                        {todosEstados.map((estado) => (
                                            //@ts-ignore
                                            <option key={estado.id} className="text-login-black" value={estado.id}>{estado.description}</option>
                                        ))}

                                    </select>
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        name="originCityId"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected disabled={true} value='' className="text-login-black">Cidade de Saída</option>
                                        {
                                            estadoSaida && todosEstados.map((estado: any) => {
                                                if(estado.id+"" === estadoSaida+""){
                                                    return estado.cities.map((cidade: any) => {
                                                        return <option key={cidade.id} className="text-login-black" value={cidade.id}>{cidade.description}</option>
                                                    })
                                                }
                                            })
                                        }
                                    </select>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={(e) => {
                                            handleFormChange(e);
                                            //@ts-ignore
                                            setEstadoChegada(e.target.value);
                                        }}
                                        
                                    
                                        name="destinationStateId"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Estado de Chegada</option>
                                        {todosEstados.map((estado) => (
                                            //@ts-ignore
                                            <option key={estado.id} className="text-login-black" value={estado.id}>{estado.description}</option>
                                        ))}
                                    </select>
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        name="destinationCityId"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Cidade de Chegada</option>
                                        {
                                            estadoChegada && todosEstados.map((estado: any) => {
                                                if(estado.id+"" === estadoChegada+""){
                                                    return estado.cities.map((cidade: any) => {
                                                        return <option key={cidade.id} className="text-login-black" value={cidade.id}>{cidade.description}</option>
                                                    })
                                                }
                                            })
                                        }
                                    </select>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        name="cargoTypeId"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Tipo de Carga</option>
                                        {/* @ts-ignore */}
                                        {tipoCarga && tipoCarga.map((carga: any) => {
                                            return (
                                                <option key={carga.id} className="text-login-black" value={carga.id}>{carga.description}</option>
                                            )
                                        })}
                                        
                                    </select>
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        
                                        name="carrierTypeId"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Tipo do Transporte</option>
                                        {/* @ts-ignore */}
                                        {tipoTransporte && tipoTransporte.map((transporte: any)=>{
                                            return(
                                                <option key={transporte.id} className='text-login-black' value={transporte.id}>{transporte.description}</option>
                                            )
                                        })}
                                    </select>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow id="inputs-mt-0">
                                <MDBCol md="6" lg="6" xl="6">
                                    <label style={{display:"flex"}}>Data de partida</label>
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        value={formDataFrete.freightDate}
                                        className="form-control text-login"
                                        name="freightDate"
                                        required
                                        type="date"
                                        aria-label="AAAA-MM-DD"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <label style={{display:"flex"}}>Horário de partida</label>
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        value={formDataFrete.time}
                                        className="form-control text-login"
                                        name="time"
                                        required
                                        type="time"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>

                            </MDBRow>

                            <div className="form-group">
                                <label className="text-login-black" htmlFor="textareaAdicionaFrete">
                                    Observações do Espaço:
                                </label>
                                <textarea
                                    onChange={handleFormChange}
                                    className="form-control text-login-black border-black"
                                    name="description"
                                    id="textareaAdicionaFrete"
                                    //@ts-ignore
                                    rows="4"
                                    style={{width: "100%"}}
                                    placeholder="Se houver algum tipo de carga que seu veículo não possa carregar, descreva aqui..."
                                />
                            </div>
                            <div className="text-right">
                                {/* @ts-ignore */}
                                <MDBBtn id="btn-cadastro-veiculo" size="md" type={"submit"} className="background-azul btn white-text">
                                    Cadastrar
                                </MDBBtn>
                            </div>
                        </form>
                    </MDBModalBody>
                </MDBModal>
            </div>
        </>
    )
}

export default FormAdicionar;