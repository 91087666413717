import { useState } from 'react';
import {
    //@ts-ignore
    toast,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBIcon,
    MDBBtn,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
} from "mdbreact";
import { getDataForm } from '../../../services/utils';
import appApi from '../../../services/appApi';

//@ts-ignore
const FormAtualizar = ({frete, handleAtualizaFrete}) => {

    const [atualizaFrete, setAtualizaFrete] = useState(false);
    const [todosEstados, setTodosEstados] = useState([]);
    const [todosMotoristas, setTodosMotoristas] = useState([]);
    const [todosVeiculos, setTodosVeiculos] = useState([]);
    const [formDataFrete, setFormDataFrete] = useState(frete);
    const [estadoChegada, setEstadoChegada] = useState(frete.destination_state_id);
    const [estadoSaida, setEstadoSaida] = useState(frete.origin_state_id);
    const [tipoCarga, setTipoCarga] = useState([])
    const [tipoTransporte, setTipoTransporte] = useState(null)

    const toggleAtualizaFrete = () => {
        setAtualizaFrete(!atualizaFrete);
        if(!atualizaFrete){
            appApi.get('/states').then(response => {
                setTodosEstados(response.data.data);
             });
            appApi.get('/drivers').then((response) => {
                setTodosMotoristas(response.data.data);
            });
            appApi.get('/vehicles').then((response) => {
                setTodosVeiculos(response.data.data);
            });
            appApi.get('/cargo_types').then((response)=>{
                setTipoCarga(response.data.data)
            });
            appApi.get('/carrier_types').then((response)=>{
                setTipoTransporte(response.data)
            })
        }
    }

    const handleFormChange = (event: any) => {
        const inputName = event.target.name;
        formDataFrete[inputName] = event.target.value;
        setFormDataFrete(formDataFrete);
    }

    const AtualizarFrete = (event: any) => {
        event.preventDefault();
        appApi.put("/freights/" + frete.id, formDataFrete).then((response) => {
            toast.success(<span><MDBIcon icon="check" /> &nbsp; Espaço atualizado com sucesso!</span>, {
                closeButton: false,
                autoclose: 5000
            });
            handleAtualizaFrete(response.data);
            toggleAtualizaFrete();
        })
        .catch((error) => {
            if (error.response.status === 500) {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
                return;
            } else if(error.response.status == 403){
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Não foi possível atualizar pois o processo já foi concluído.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            } else {
                
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro em algum campo do Formulário.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            }

        })
    }

    return (
        <>
            <MDBBtn 
                className="background-azul btn white-text"
                onClick={toggleAtualizaFrete} 
            >
                Atualizar detalhes
            </MDBBtn>
            <div id="modalAdicionarFrete">
                <MDBModal isOpen={atualizaFrete} centered className="border-radius-adicionar-motorista">
                    <MDBModalHeader toggle={toggleAtualizaFrete} className="background-azul white-text modal-header-h4">
                        Atualizar Espaço
                    </MDBModalHeader>
                    <MDBModalBody className="margin-laterais-15">
                        <form method={"post"} onSubmit={AtualizarFrete}>
                            <MDBRow>
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.height}
                                        className="form-control text-login"
                                        label="Altura disponível"
                                        name="height"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.width}
                                        className="form-control text-login"
                                        label="Largura disponível"
                                        name="width"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow id="inputs-mt-0">
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.depth}
                                        className="form-control text-login"
                                        label="Profundidade disponível"
                                        name="depth"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.volume}
                                        className="form-control text-login"
                                        label="Espaço em PBR"
                                        name="volume"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow id="inputs-mt-0">
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.price}
                                        className="form-control text-login"
                                        label="Preço"
                                        name="price"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.weight}
                                        className="form-control text-login"
                                        label="Peso"
                                        name="weight"
                                        required
                                        type="text"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow id="inputs-mt-0">
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.vehicle_id}
                                        name="vehicle_id"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Veículo</option>
                                        {todosVeiculos.map((veiculo) => (
                                            //@ts-ignore
                                            <option className="text-login-black" value={veiculo.id}>{veiculo.plate}</option>
                                        ))}
                                    </select>
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.driver_id}
                                        name="driver_id"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Motorista</option>
                                        {todosMotoristas.map((motorista) => (
                                            //@ts-ignore
                                            <option className="text-login-black" value={motorista.id}>{motorista.name}</option>
                                        ))}
                                    </select>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={(e) => {
                                            handleFormChange(e);
                                            //@ts-ignore
                                            setEstadoSaida(e.target.value);
                                        }}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.origin_state_id}
                                        name="origin_state_id"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Estado de Saída</option>
                                        {todosEstados.map((estado) => (
                                            //@ts-ignore
                                            <option className="text-login-black" value={estado.id}>{estado.description}</option>
                                        ))}

                                    </select>
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.origin_city_id}
                                        name="origin_city_id"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected disabled={true} value='' className="text-login-black">Cidade de Saída</option>
                                        {
                                            estadoSaida && todosEstados.map((estado: any) => {
                                                if(estado.id+"" === estadoSaida+""){
                                                    return estado.cities.map((cidade: any) => {
                                                        return <option className="text-login-black" value={cidade.id}>{cidade.description}</option>
                                                    })
                                                }
                                            })
                                        }
                                    </select>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={(e) => {
                                            handleFormChange(e);
                                            //@ts-ignore
                                            setEstadoChegada(e.target.value);
                                        }}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.destination_state_id}
                                        name="destination_state_id"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Estado de Chegada</option>
                                        {todosEstados.map((estado) => (
                                            //@ts-ignore
                                            <option className="text-login-black" value={estado.id}>{estado.description}</option>
                                        ))}
                                    </select>
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.destination_city_id}
                                        name="destination_city_id"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Cidade de Chegada</option>
                                        {
                                            estadoChegada && todosEstados.map((estado: any) => {
                                                if(estado.id+"" === estadoChegada+""){
                                                    return estado.cities.map((cidade: any) => {
                                                        return <option className="text-login-black" value={cidade.id}>{cidade.description}</option>
                                                    })
                                                }
                                            })
                                        }
                                    </select>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.cargo_type_id}
                                        name="cargo_type_id"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Tipo de Carga</option>
                                        {tipoCarga && tipoCarga.map((carga: any)=>{
                                            return(
                                                <option className="text-login-black" value={carga.id}>{carga.description}</option>
                                            )
                                        })}
                                    </select>
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <select
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.carrier_type_id}
                                        name="carrier_type_id"
                                        className="form-data text-login-black select-personalizado cursor-pointer"
                                        required
                                    >
                                        <option selected={true} disabled={true} value='' className="text-login-black">Tipo do Transporte</option>
                                        {/* @ts-ignore */}
                                        {tipoTransporte && tipoTransporte.map((transporte)=>{
                                            return(
                                                //@ts-ignore
                                                <option className="text-login-black" value={transporte.id}>{transporte.description}</option>
                                            )
                                        })}
                                    </select>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow id="inputs-mt-0">
                                <MDBCol md="6" lg="6" xl="6">
                                    <label style={{display:"flex"}}>Data de partida</label>
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={getDataForm(formDataFrete.freight_date)}
                                        className="form-control text-login"
                                        name="freightDate"
                                        required
                                        type="date"
                                        aria-label="AAAA-MM-DD"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>
                                <MDBCol md="6" lg="6" xl="6">
                                    <label style={{display:"flex"}}>Horário de partida</label>
                                    <MDBInput
                                        onChange={handleFormChange}
                                        //@ts-ignore
                                        valueDefault={formDataFrete.time}
                                        className="form-control text-login"
                                        name="time"
                                        required
                                        type="time"
                                        labelClass="text-login"
                                        style={{width: "100%"}}
                                    />
                                </MDBCol>

                            </MDBRow>

                            <div className="form-group">
                                <label className="text-login-black" htmlFor="textareaAdicionaFrete">
                                    Observações do Espaço:
                                </label>
                                <textarea
                                    className="form-control text-login-black border-black"
                                    id="textareaAdicionaFrete"
                                    defaultValue={formDataFrete.description}
                                    //@ts-ignore
                                    rows="4"
                                    style={{width: "100%"}}
                                    placeholder="Se houver algum tipo de carga que seu veículo não possa carregar, descreva aqui..."
                                />
                            </div>
                            <div className="text-right">
                                {/* @ts-ignore */}
                                <MDBBtn id="btn-cadastro-veiculo" size="md" type={"submit"} className="background-azul btn white-text">
                                    Confirmar
                                </MDBBtn>
                            </div>
                        </form>
                    </MDBModalBody>
                </MDBModal>
            </div>
        </>
    )
}

export default FormAtualizar;