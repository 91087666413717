import {
    MDBIcon,
} from 'mdbreact';
import { Link } from 'react-router-dom';

interface IProps {
    name: string
    route: string
    iconName: string
}

const SideNavItem = ({ name, route, iconName }: IProps) => {

    const pathName = window.location.pathname;

    return (
        <li>
            <Link
                to={route}
                className = {pathName === route ? "waves-effect active" : "waves-effect"}
            >
                <MDBIcon icon={iconName} className="mr-2" />
                {' '}
                {name}
            </Link>
        </li>
    )
}

export default SideNavItem