import { useState, useEffect } from "react";
import {
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBAnimation,
    MDBCard,
    MDBInput,
  
  } from 'mdbreact';
import InputMask from "react-input-mask";
import appApi from "../../../services/appApi";
import FormAdicionar from "../FormAdicionar";
import { getDataFormatada } from "../../../services/utils";
import Paginator from "../../Paginator";
import Loading from "../../Loading/loading";
import { IVeiculo } from "../../../types/veiculo.interface";
import { IFreight, IFreightInfo } from "../../../types/freights";
import { IMotorista } from "../../../types/motorista.interface";

//@ts-ignore
const DetalhesVeiculo = ({idVeiculo, botaoVoltar, handleNovoVeiculo}) => {

    const [motorista, setMotorista] = useState<IMotorista | null>(null);

    const [veiculo, setVeiculo] = useState<IVeiculo | null>(null);
    const [viagens, setViagens] = useState<IFreightInfo[]>([]);
    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        appApi.get('/vehicles/'+idVeiculo).then(response => {
          setVeiculo(response.data[0]);
        });
    }, []);

    useEffect(() => {
      appApi.get('/vehicles/'+idVeiculo+'/freights?page='+page).then(res => {
          setViagens(res.data.data);
          const qt = res.data.meta.total / res.data.meta.per_page;
          const resto = res.data.meta.total % res.data.meta.per_page;
          setQuantity(qt + (resto > 0 ? 1 : 0));
      });
  }, []);
  
    return (
        <MDBAnimation type="fadeIn">
              <MDBRow className="mb-3 ml-2">
                <button onClick={botaoVoltar} className="button-go-back">
                  <MDBIcon icon="arrow-left" />&nbsp;
                  Voltar
                </button>
              </MDBRow>
              {veiculo == null ? <Loading  name="detalhes do veículo"/> :
              <>
              <MDBRow className="mt-2 mb-3">
                {/* <MDBCol md="3" col="3" lg="3" xl="3">
                  <MDBCard>
                    <MDBRow className='mt-3'>
                      <MDBCol md='12' col='12' className='text-left pl-5'>
                        <h6 className='mt-2 mb-2 font-weight-bold'>Total KM rodados</h6>
                        <h3 className='mt-3 mb-4 font-weight-bold'>{veiculo.meta.distance || 0} </h3>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </MDBCol> */}

                {/* <MDBCol md="3" col="3" lg="3" xl="3">
                  <MDBCard>
                    <MDBRow className='mt-3'>
                      <MDBCol md='12' col='12' className='text-left pl-5'>
                        <h6 className='mt-2 mb-2 font-weight-bold'>Total de viagens</h6>
                        <h3 className='mt-3 mb-4 font-weight-bold'>10.000 </h3>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </MDBCol>

                <MDBCol md="3" col="3" lg="3" xl="3">
                  <MDBCard>
                    <MDBRow className='mt-3'>

                      <MDBCol md='12' col='12' className='text-left pl-5'>
                        <h6 className='mt-2 mb-2 font-weight-bold'>Total de rotas</h6>
                        <h3 className='mt-3 mb-4 font-weight-bold'>2.000 </h3>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </MDBCol>

                <MDBCol md="3" col="3" lg="3" xl="3">
                  <MDBCard>
                    <MDBRow className='mt-3'>
                      <MDBCol md='12' col='12' className='text-left pl-5'>
                        <h6 className='mt-2 mb-2 font-weight-bold'>Status atual</h6>
                        <h3 className='mt-3 mb-4 font-weight-bold'>Em rota </h3>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
              </MDBCol> */}
              </MDBRow>

              <div id="section-listar-veiculos" className="mt-3">
                <MDBRow>
                  <MDBCol md="12">
                    <div id="form-vehicle">
                      <MDBRow>
                        <MDBCol md="12" lg="6" xl="6">
                          <div className="ml-3 mr-3">
                            <h3>Informações do Veículo</h3>
                            <form method={"post"}>
                              <div className="md-form">
                                <label className={"company-cnpj-mask-label color-disabled"}>Placa do Veículo</label>
                                <InputMask
                                  value={veiculo.plate}
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black text-uppercase"}
                                  mask="aaa-9*99"
                                  name="plate"
                                  required
                                  type="text"
                                  placeholder="XXX-XXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                                <label className={"color-disabled company-cnpj-mask-label"}>Renavam do Veículo</label>
                                <InputMask
                                  value={veiculo.renavam}
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black" }
                                  mask="99999999999"
                                  name="renavam"
                                  required
                                  type="text"
                                  placeholder="XXXXXXXXXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                                <label className={"company-cnpj-mask-label color-disabled"}>Ano do Veículo</label>
                                <InputMask
                                  value={veiculo.vehicle_year}
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black text-uppercase"}
                                  mask=""
                                  name="year"
                                  required
                                  type="text"
                                  placeholder="XXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                                <label className={"color-disabled company-cnpj-mask-label"}>País do Veículo</label>
                                <InputMask
                                  value={veiculo.country_id}
                                  mask=""
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black"}
                                  name="country"
                                  required
                                  type="text"
                                  placeholder="XXXXXXXXXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                                <label className={"color-disabled company-cnpj-mask-label"}>Estado do Veículo</label>
                                <InputMask
                                  value={veiculo.state.description}
                                  mask=""
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black"}
                                  name="state"
                                  required
                                  type="text"
                                  placeholder="XXXXXXXXXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                                <label className={"color-disabled company-cnpj-mask-label"}>Tipo do Veículo</label>
                                <InputMask
                                  value={veiculo.vehicleType.description}
                                  mask=""
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black"}
                                  name="type"
                                  required
                                  type="text"
                                  placeholder="XXXXXXXXXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                                <label className={"color-disabled company-cnpj-mask-label"}>Fabricante</label>
                                <InputMask
                                  value={veiculo.vehicleManufacturer.description}
                                  mask=""
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black"}
                                  name="manufacturer"
                                  required
                                  type="text"
                                  placeholder="XXXXXXXXXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                                <label className={"color-disabled company-cnpj-mask-label"}>Modelo</label>
                                <InputMask
                                  value={veiculo.vehicleModel.description}
                                  mask=""
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black"}
                                  name="model"
                                  required
                                  type="text"
                                  placeholder="XXXXXXXXXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                                <label className={"color-disabled company-cnpj-mask-label"}>Descrição do veículo</label>
                                
                                <MDBInput
                                  value={veiculo.vehicle_desc}
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black"}
                                  name="desc"
                                  required
                                  type="textarea"
                                  disabled={true}
                                />
                              </div>

                              <div className="md-form">
                              <label className={"company-cnpj-mask-label color-disabled"}>RNTRC</label>
                                <InputMask
                                  value={veiculo.rntrc}
                                  className={"form-control color-disabled vehicle-renavam-mask-input border-bottom-black text-uppercase"}
                                  mask=""
                                  name="year"
                                  required
                                  type="text"
                                  placeholder="XXXX"
                                  disabled={true}
                                />
                              </div>

                              <div className="text-center">
                                <FormAdicionar handleNovoVeiculo={handleNovoVeiculo} isEdit={true} editInfo={veiculo} />
                              </div>
                            </form>
                          </div>
                        </MDBCol>

                        <MDBCol md="12" lg="6" xl="6" className="mt-3">
                          <div className="ml-3 mr-3">
                            <h3>Histórico do Veículo</h3>
                            <MDBTable className="mb-5 mt-3" responsive>
                              <MDBTableHead className="font-weight-bold">
                                <tr>
                                  <th>Origem</th>
                                  <th>Destino</th>
                                  <th>Data</th>
                                  <th>Distância</th>
                                  <th>Motorista</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                              {
                                viagens.map((item: IFreightInfo) => {
                                    return (<tr className="table-body-hover" key={item.id}>
                                                <td>{item.originCity.description}</td>
                                                <td>{item.destinationCity.description}</td>
                                                <td>{getDataFormatada(item.freight_date)}</td>
                                                <td>{item.distance + ' km'}</td>
                                                <td>{item.driver_id}</td>                             
                                            </tr>)                                             
                                })}
                              </MDBTableBody>
                            </MDBTable>
                            <Paginator quantity={quantity} onSelect={setPage} />
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
              </>}
            </MDBAnimation>
    ) 
}

export default DetalhesVeiculo;
