import { useState, useEffect } from "react";
import {
    MDBIcon,
    MDBRow,
    MDBCol,
} from 'mdbreact';
import Layout from "./layout";
import '../styles/veiculos.css';
import appApi from "../services/appApi";
import ListaVeiculos from "../components/MeusVeiculos/ListaVeiculos";
import FormAdicionar from "../components/MeusVeiculos/FormAdicionar";
import DetalhesVeiculo from "../components/MeusVeiculos/DetalhesVeiculo";
import Paginator from "../components/Paginator";
import Loading from '../components/Loading/loading';
import { IVeiculo } from "../types/veiculo.interface";

const MeusVeiculos = () => {

    const [listaDeVeiculos, setListaDeVeiculos] = useState<IVeiculo[]>([]);
    const [idInfoVeiculo, setIdInfoVeiculo] = useState<number | null>(null);
    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        appApi.get("/vehicles?page=" + page).then(res => {
            setListaDeVeiculos(res.data.data);
            const qt = res.data.meta.total / res.data.meta.per_page;
            const resto = res.data.meta.total % res.data.meta.per_page;
            setQuantity(qt + (resto > 0 ? 1 : 0));
        });
    }, [page]);

    const handleNovoVeiculo = (novoVeiculo: any, isEdit = false) => {
        if (isEdit) {
            const novaListaDeVeiculos = listaDeVeiculos.map((item: IVeiculo) => {
                if (item.id === novoVeiculo.id) {
                    item = novoVeiculo
                }
                return item
            })
            setListaDeVeiculos(novaListaDeVeiculos);
        } else {
            const novaListaDeVeiculos = listaDeVeiculos.concat(novoVeiculo);
            setListaDeVeiculos(novaListaDeVeiculos);
        }
    }

    const handleExcluirVeiculo = (idVeiculo: number) => {

        const veiculosAtualizados = listaDeVeiculos.filter((veiculo: IVeiculo) => {
            return veiculo.id !== idVeiculo
        });

        setListaDeVeiculos(veiculosAtualizados);
    }

    return (
        <Layout>
            {idInfoVeiculo === null ? (
                <>
                    <MDBRow id="pagina-veiculos" style={{ marginBottom: "40px", width: "100%" }}>
                        <MDBCol md="12" lg="12" xl="12">
                            <MDBRow>
                                <MDBCol md="12" lg="8" xl="8" className="text-left">
                                    <h3 className="font-weight-bold color-azul"><MDBIcon icon="truck-moving"/> Meus Veículos</h3>
                                </MDBCol>
                                <FormAdicionar handleNovoVeiculo={handleNovoVeiculo} />
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    {listaDeVeiculos.length <= 0 ? <Loading name="meus veículos"/> : 
                        <ListaVeiculos listaDeVeiculos={listaDeVeiculos} handleExcluirVeiculo={handleExcluirVeiculo} setIdInfoVeiculo={setIdInfoVeiculo} />
                    }
                    <Paginator quantity={quantity} onSelect={setPage} />
                </>
            ) : (
                <DetalhesVeiculo idVeiculo={idInfoVeiculo} botaoVoltar={() => setIdInfoVeiculo(null)} handleNovoVeiculo={handleNovoVeiculo} />
            )}
        </Layout>
    )
}
export default MeusVeiculos;