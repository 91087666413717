export const getDataFormatada = (data) => {

    data = new Date(data);
    const dataFormatada = (data.getDate() <= 9 ? "0" : "") + data.getDate() + "/" + (data.getMonth() + 1 <= 9 ? "0" : "") + (data.getMonth() + 1) + "/" + data.getFullYear();

    return dataFormatada;

}

export const getDataForm = (data) => {

    data = new Date(data);
    const dataFormatada = data.getFullYear() + "-" + (data.getMonth() + 1 <= 9 ? "0" : "") + (data.getMonth() + 1) + "-" + (data.getDate() <= 9 ? "0" : "") + data.getDate();

    return dataFormatada;

}

export const getTelefoneFormatado = (telefone) => {

    const ddd = telefone.slice(0, 2)
    const numero = telefone.slice(2,)

    if(numero.length === 9){
        return `(${ddd})${telefone.slice(2,7)}-${telefone.slice(7,)}`
    }else if(numero.length === 8){
        return `(${ddd})${telefone.slice(2,6)}-${telefone.slice(6,)}`
    }
}

export const dataFormatada = (date) => {
    const data = new Date(date);
    function adicionaZero(numero) {
        if (numero <= 9) {
            return "0" + numero;
        }
        return numero;
    }
    const dataFormatada =
        ((adicionaZero(data.getDate()).toString()) + "/" +
            ((adicionaZero(data.getMonth() + 1).toString())) + "/" +
                (data.getFullYear()));

    return dataFormatada
}