import appApi from "../services/appApi";

export const isAuthenticated = () => {
    return getToken() !== null
};
export const TOKEN_KEY = "@shareeconomy_token";

export const getToken = () => sessionStorage.getItem(TOKEN_KEY)

export const login = (token: string) => {
    sessionStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
    appApi.delete('/logout').then((response)=>{
        if(response.status == 200){
            sessionStorage.removeItem(TOKEN_KEY)
        }
    })
};



