
import {
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBtn,
    MDBCard,
} from "mdbreact";

//@ts-ignore
const Resumo = ({todos}) => {
    return (
        <MDBRow className="mt-2 mb-3">
            <MDBCol md="3" col="3" lg="3" xl="3">
                <MDBCard 
                    className="modal-hover-fretes cursor-pointer" 
                    //onClick={toggleTodosFretes}
                    //style={{ backgroundColor: todosFretes ? '#EEEEEE' : '' }}
                >
                    <MDBRow className='mt-3'>
                        <MDBCol md='4' size='4' className='text-left pl-2'>
                            <MDBBtn
                                //onClick={toggleTodosFretes}
                                id='btn-truck-loading'
                                tag='a'
                                floating
                                //@ts-ignore
                                size='md'
                                className='ml-4 p-0 z-depth-0'
                            >
                                <MDBIcon icon='truck-loading' />
                            </MDBBtn>
                        </MDBCol>
                        <MDBCol md='8' col='8' className='text-right pr-5 column-8-mobile'>
                            <h5 className='mt-2 mb-2 font-weight-bold'>Todos</h5>
                            <h6 className='mt-3 mb-4 font-weight-bold'>{todos} </h6>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    )
}

export default Resumo;