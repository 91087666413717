import React, { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBMask,
    MDBInput,
    MDBView,
    MDBBtn,
    MDBIcon,
    MDBSelect,
    //@ts-ignore
    ToastContainer,
    //@ts-ignore
    toast,
} from 'mdbreact';
import { useHistory } from "react-router-dom";
import appApi from '../services/appApi';
import { usePasswordValidation } from './usePasswordValidation';
import '../styles/login.css';
import '../styles/cadastro.css';
import logoColorida from '../images/logoColorida.svg';

const RedefiniçãoSenha: React.FC = () => {

    let history = useHistory();

    interface IPasswords {
        firstPassword: string
        secondPassword: string
    }

    const [resetPassword, setResetPassword] = useState<IPasswords>({} as IPasswords);
    const [senhaMostrando, setSenhaMostrando] = useState(true);

    // mostrar senha
    const toggleMostraSenha = () => {
        setSenhaMostrando(!senhaMostrando);
    }

    const { match } = usePasswordValidation(resetPassword);

    const setFirst = (event: any) => {
        setResetPassword({ ...resetPassword, firstPassword: event.target.value }); 
    };

    const setSecond = (event: any) => {
        setResetPassword({ ...resetPassword, secondPassword: event.target.value });
    };

    const update = () => { 
        let token = document.location.search.substring(1);
        appApi.put('/reset_fpassword', resetPassword, { params: { token } }).then((response)=>{
            if (response.status === 200){
                toast.success(<span><MDBIcon icon="check" /> &nbsp; Senha alterada com sucesso! </span>, {
                    closeButton: false
                });
                setTimeout(()=>{
                    history.push('/autenticacao')
                }, 3000)    
            }
        }).catch((error)=>{
            if(error.response.status == 410){
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Link expirado ou não encontrado.</span>, {
                    closeButton: false
                });
            } else if (error.response.status == 409){
                toast.error(<span><MDBIcon icon="times" /> &nbsp; {error.response.data.message}</span>, {
                    closeButton: false
                });
            } else if (error.response.status == 400) {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; {error.response.data.message}</span>, {
                    closeButton: false
                });   
            }
        })
    }
    
    return (
        <div id="parte-redefinir-senha">
            <MDBView>
            <ToastContainer
                hideProgressBar
                newestOnTop
                autoClose={5000}
            />
                <MDBMask>
                    <div id="div-fundo">
                        <div id="fundo-login-cadastro">
                            <div id="login-cadastro">
                                <MDBContainer>
                                    <MDBRow>
                                        <div className="text-center w-100 mt-5 mb-5">
                                        <a href="/">
                                            <img src={logoColorida} width="300px" className="d-inline-block" alt="Logo Share Economy" />
                                        </a>                                                 
                                        </div>
                                    </MDBRow>
                                    <MDBRow id="forms-login-cadastro" className="w-100 mb-5">
                                        <MDBCol className="mt-5 mx-auto" md="10" lg="6" xl="5" sm="12">
                                            <MDBCard className="card-image background-amarelo border-radius-20">
                                                <div className="text-white py-5 px-5 z-depth-4" id="form-login">
                                                    <div className="text-center">
                                                        <h3 className="text-login mt-4 font-weight-bold">
                                                            <strong>Nova Senha</strong>
                                                        </h3>
                                                        <p className="text-login mb-4">Senhas <b>devem</b> coincidir. </p>
                                                        <form>
                                                            <MDBInput
                                                                className="form-control text-login"
                                                                label="Insira sua nova senha"
                                                                name="firstPassword"
                                                                onChange={setFirst}
                                                                required
                                                                type={senhaMostrando ? "password" : "text"}
                                                                labelClass="text-login"
                                                                style={{width: "100%"}}
                                                            />
                                                            <MDBInput
                                                                className="form-control text-login"
                                                                label="Repita sua nova senha"
                                                                name="secondPassword"
                                                                onChange={setSecond}
                                                                required
                                                                type={senhaMostrando ? "password" : "text"}
                                                                labelClass="text-login"
                                                                style={{width: "100%"}}
                                                            />
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                                                <MDBIcon className="icon-show-password" 
                                                                    icon={senhaMostrando ? "eye" : "eye-slash"} onClick={toggleMostraSenha} />
                                                            </div>
                                                            <MDBBtn className="background-azul" disabled={!match} rounded onClick={update}>Redefinir Senha</MDBBtn>
                                                            
                                                        </form>
                                                        {!match  && <p className='danger'>
                                                            As senhas <b>não</b> coincidem!
                                                        </p>}
                                                    </div>
                                                </div>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </div>
                        </div>
                    </div>
                </MDBMask>
            </MDBView>
        </div>
    );
}

export default RedefiniçãoSenha;