import React, { useState, useEffect } from 'react'
import { MDBAlert, MDBIcon, MDBRow, MDBCol } from 'mdbreact';
import appApi from "../services/appApi";
import Layout from './layout';
import "../styles/meus-dados.css"


const MeusDados:React.FC = () => {

    const [activeForm, setActiveForm] = useState<any>(null);
    const [email, setEmail] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [image, setImage] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [picture, setPicture] = useState<any>(null);
    const [alert, setAlert] = useState<any>({show: false, text: '', style: 'success'});

    useEffect(() => {
        appApi.get(`/users/me`)
        .then(res => {
            setCompanyName(res.data.company.name);
            setEmail(res.data.email);
            if(res.data.company.picture !== null){
                setImage(res.data.company.picture.path.replace("localhost", "https://api.shareeconomy.com.br"));        
            }
            
        })
        .catch(err => {
            setAlert({show: true, text: 'Erro inesperado. Tente novamente mais tarde', style: 'danger'});
        });
    }, [])

    const handleShowAlert = (newAlert: any) => {
        setAlert(newAlert);
        setTimeout(() => hideAlert(), 5000);
    }

    const hideAlert = () => {
        const newAlert = {...alert, show: false};
        setAlert(newAlert);
    }

    const handleSetActiveForm = (index: number) => {
        setActiveForm(index === activeForm ? null : index);
    }

    const handleChangeCompanyName = () => {
        if(companyName.length > 0){
            appApi.put(`/users/me`, {company_name: companyName})
            .then(res => {
                if (res.status === 200){
                    handleShowAlert({show: true, text: 'Nome da empresa alterado com sucesso!', style: 'success'});
                }else{
                    handleShowAlert({show: true, text: 'Erro ao alterar nome da empresa', style: 'danger'});
                }
            })
            .catch(err => {                
                handleShowAlert({show: true, text: 'Erro inesperado. Tente novamente mais tarde', style: 'danger'});
            });
        }else{
            handleShowAlert({show: true, text: 'O campo "Nome da empresa" não pode ser vazio', style: 'danger'});
        }
    }

    const handleSetPicture = (files: any) => {
        if (files && files[0]) {
            setImage(URL.createObjectURL(files[0]));
            setPicture(files);
        }
    }

    const handleUploadPicture = () => {
        if(picture !== null){
            const formData = new FormData();
            formData.append("files", picture[0]);
            appApi.post(`/files`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                handleChangePicture(res.data[0].id);
            })
            .catch(err => {
                handleShowAlert({show: true, text: 'Erro inesperado. Tente novamente mais tarde', style: 'danger'});
            });
        }else{
            handleShowAlert({show: true, text: 'Você precisa selecionar uma imagem', style: 'danger'});
        }
    }

    const handleChangePicture = (id: number) => {
        appApi.put(`/users/me/picture`, {picture_id: id})
            .then(res => {
                if(res.status === 200){
                    handleShowAlert({show: true, text: 'Imagem da empresa alterada com sucesso!', style: 'success'});
                }else{
                    handleShowAlert({show: true, text: 'Erro ao alterar imagem da empresa', style: 'danger'});
                }
            })
            .catch(err => {
                handleShowAlert({show: true, text: 'Erro inesperado. Tente novamente mais tarde', style: 'danger'});
            });
    }

    const changePassword = () => {
        if(newPassword.length > 0 && newPassword === passwordConfirmation){
            appApi.put(`/users/me/password`, {password: newPassword, currentPassword: currentPassword})
            .then(res => {
                if(res.status === 200){
                    handleShowAlert({show: true, text: 'Sua senha foi alterada com sucesso!', style: 'success'});             
                }
            })
            .catch(error => {
                if(error.status === 500) {
                    handleShowAlert({show: true, text: 'Erro ao alterar sua senha', style: 'danger'});
                }else{
                    handleShowAlert({show: true, text: 'Senha atual incorreta!', style: 'danger'});
                }
            })
        }
    }

    return (
        <Layout>
            <MDBCol md="12" lg="12" xl="12">
                <MDBRow style={{marginBottom: "40px"}}>
                    <MDBCol md="12" lg="12" xl="12">
                        <h3 className="font-weight-bold color-azul"><MDBIcon icon="address-book" /> Meus dados</h3>
                    </MDBCol>
                </MDBRow>
                {alert.show && 
                <MDBRow onClick={hideAlert}>
                    <MDBCol md="12" lg="12" xl="12">    
                        <MDBAlert color={alert.style} >
                            {alert.text}
                        </MDBAlert>
                    </MDBCol>
                </MDBRow>}
                <MDBRow>
                    <MDBCol md="12" lg="12" xl="12" className="list-dados">
                        <MDBRow className="list-item">
                            <div onClick={() => handleSetActiveForm(0)} className="item-title">
                                <span>Dados da empresa</span>
                                <div className={`expand-icon ${activeForm === 0 && 'expanded'}`} />
                            </div>
                            {activeForm === 0 && <div className="form-container">
                                <MDBRow>
                                    <MDBCol md="12" lg="12" xl="12">
                                        <MDBRow>
                                                    <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                                        <h5 style={{marginLeft: "5px", fontWeight: 500}}>Seu e-mail:</h5>
                                                        <input className="form-control mb-4" type="text" placeholder="Seu e-mail" value={email} disabled={true}/>
                                                    </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                                <h5 style={{marginLeft: "5px", fontWeight: 500}}>Nome da empresa:</h5>
                                                <input className="form-control mb-4" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder="Nome da sua Empresa"/>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                            <button onClick={handleChangeCompanyName} className="btn-confirm">
                                                Confirmar
                                            </button>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </div>}
                        </MDBRow>
                        <MDBRow className="list-item">
                            <div onClick={() => handleSetActiveForm(1)} className="item-title">
                                <span>Foto da empresa</span>
                                <div className={`expand-icon ${activeForm === 1 && 'expanded'}`} />
                            </div>
                            {activeForm === 1 && <div className="form-container">
                                <MDBRow>
                                    <MDBCol md="12" lg="12" xl="12">
                                        <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" >
                                            <img
                                                src={image}
                                                className="img-thumbnail"
                                                alt="..."
                                                width="150"
                                                height="150"
                                            />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                                <span>Escolher uma nova foto para a empresa:</span>
                                                <input className="form-control mb-4" type="file" onChange={(e) => handleSetPicture(e.target.files)} placeholder="Foto da empresa"/>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                                <button onClick={handleUploadPicture} className="btn-confirm">
                                                    Confirmar
                                                </button>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </div>}
                        </MDBRow>
                        {/* Alterar Senha */}
                        <MDBRow className="list-item">
                            <div onClick={() => handleSetActiveForm(2)} className="item-title">
                                <span>Alterar senha</span>
                                <div className={`expand-icon ${activeForm === 2 && 'expanded'}`} />
                            </div>
                            {activeForm === 2 && <div className="form-container">
                                <MDBRow>
                                    <MDBCol md="12" lg="12" xl="12">
                                    <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                                <h5 style={{marginLeft: "5px", fontWeight: 500}}>Sua senha atual:</h5>
                                                <input className="form-control mb-4" type="password" onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Digite sua senha atual"/>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                                <h5 style={{marginLeft: "5px", fontWeight: 500, color: "green"}}>Sua nova senha:</h5>
                                                <input className="form-control mb-4" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Digite uma nova senha"/>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                                <h5 style={{marginLeft: "5px", fontWeight: 500, color: "green"}}>Repita sua nova senha:</h5>
                                                <input className="form-control mb-4" type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} placeholder="Repita a nova senha" style={{borderColor: newPassword !== passwordConfirmation ? 'red' : '#ccc'}}/>
                                                {newPassword !== passwordConfirmation && <span style={{color: 'red'}}>Os campos de senha precisam estar iguais!</span>}
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12" lg="12" xl="12" className="pt-1">
                                            <button onClick={changePassword} className="btn-confirm">
                                                Confirmar
                                            </button>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </div>}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </Layout>
    )
}

export default MeusDados;