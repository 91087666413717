import React from "react";
import Layout from "./layout";

const Usuarios = () => {
    return (
        <Layout>
            <h2>Área de Cadastro de Usuários</h2>
        </Layout>
    );
}

export default Usuarios;