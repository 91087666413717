import React, {useState, useEffect} from 'react'
import appApi from '../../../services/appApi'
import {
    //@ts-ignore
    toast,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBIcon,
    MDBBtn,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
} from "mdbreact";


const AtualizarStatus = ({ frete }: any) => {

    const [ atualizaStatus, setAtualizaStatus ] = useState<boolean>(false)
    const [ statusId, setStatusId ] = useState<number>(1)

    const toggleAtualizaStatus = () => {
        setAtualizaStatus(!atualizaStatus)
    }

    const updateStatus = (event: any) => {
        event.preventDefault()
        appApi.put(`/freights/updateStatus/${frete.id}`, {statusId}).then((response)=>{
            if(response.status === 200) {
                toast.success(<span><MDBIcon icon="check" /> &nbsp; Status alterado com sucesso!</span>, {
                    closeButton: true,
                    autoclose:2000
                });
                toggleAtualizaStatus()
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
        }).catch((error)=>{
            if(error.response.status == 403){
                    toast.error(<span><MDBIcon icon="check" /> &nbsp; Não é possível atualizar o status porque o processo já foi concluído</span>, {
                        closeButton: true,
                        autoclose: 2000
                    });
            }
        })
    }

    const handleFormChange = (event: any) => {
        setStatusId(event.target.value)
    }

    return (
        <>
            <MDBBtn 
                className="background-amarelo btn white-text"
                onClick={toggleAtualizaStatus}
            >
                Atualizar Status
            </MDBBtn>
            <div id="modalAdicionarFrete">
                <MDBModal isOpen={atualizaStatus} centered className="border-radius-adicionar-motorista">
                    <MDBModalHeader toggle={toggleAtualizaStatus} className="background-azul modal-header-h4" style={{color: 'white'}}>
                        <strong>Atualizar Status</strong>
                    </MDBModalHeader>
                    <MDBModalBody className="margin-laterais-15">
                        <form method={"post"} onSubmit={updateStatus}>
                            <div>
                                <select
                                    className="form-data text-login-black select-personalizado cursor-pointer"
                                    required
                                    onChange={handleFormChange}
                                >
                                    <option selected={true} disabled={true} value='' className="text-login-black">{frete.freightStatus.description} (Atual)</option>
                                    <option 
                                        onChange={handleFormChange}  
                                        value={1}
                                        className="text-login-black">Aberto
                                    </option>
                                    <option 
                                        onChange={handleFormChange}
                                        value={2}
                                        className="text-login-black">
                                        Fechado
                                    </option>
                                    <option 
                                        onChange={handleFormChange}
                                        value={3}
                                        className="text-login-black">
                                        Em andamento
                                    </option>
                                </select>
                            </div>
                            <div>
                                <MDBBtn id="btn-cadastro-veiculo" size="sm" type={"submit"} className="background-azul btn white-text" style={{backgroundColor: '#F6A713'}}>
                                    Confirmar
                                </MDBBtn>
                            </div>
                        </form>
                    </MDBModalBody>
                </MDBModal>
            </div>
        </>
    )
}

export default AtualizarStatus