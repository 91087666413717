import { useState } from "react";
import {
    //@ts-ignore
    toast,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
  
  } from 'mdbreact';
import InputMask from 'react-input-mask';
import appApi from "../../../services/appApi";

//@ts-ignore
const FormAdicionar = ({handleNovoVeiculo, isEdit = false, editInfo = {}}) => {
    
    const [modalAdicionaVeiculo, setModalAdicionaVeiculo] = useState(false);
    const [picture, setPicture] = useState(null);
    const [estadoVeiculo, setEstadoVeiculo] = useState([]);
    const [paisVeiculo, setPaisVeiculo] = useState([]);
    const [fabricanteVeiculo, setFabricanteVeiculo] = useState([]);
    const [tipoVeiculo, setTipoVeiculo] = useState([]);
    const [modeloVeiculo, setModeloVeiculo] = useState([]);
    const [fabricanteCadastro, setFabricanteCadastro] = useState();
    const [formDataVehicle, setFormDataVehicle] = useState<any>(!isEdit ? {} : {
        //@ts-ignore
        plate: editInfo.plate,
        //@ts-ignore
        renavam: editInfo.renavam,
        //@ts-ignore
        countryId: editInfo.country_id,
        //@ts-ignore
        stateId: editInfo.state_id,
        //@ts-ignore
        vehicleManufacturerId: editInfo.vehicle_manufacturer_id,
        //@ts-ignore
        vehicleTypeId: editInfo.vehicle_type_id,
        //@ts-ignore
        vehicleModelId: editInfo.vehicle_model_id,
        //@ts-ignore
        vehicleYear: editInfo.vehicle_year,
        //@ts-ignore
        vehicleDesc: editInfo.vehicle_desc,
        //@ts-ignore
        rntrc: editInfo.rntrc
    });

    //Busca país, estado, fabricante e modelo no DB.
    const toggleModalAdicionaVeiculo = () => {
        setModalAdicionaVeiculo(!modalAdicionaVeiculo);

        if(!modalAdicionaVeiculo){
            appApi.get("/countries").then(res => {
                //@ts-ignore
                const options = res.data.data.map((item) => {
                  return {text: item.description, value: item.id}
                });
                setPaisVeiculo(options);
            });
            appApi.get("/states").then(res => {
                //@ts-ignore 
                const options = res.data.data.map((item) => {
                    return {text: item.description, value: item.id}
                });
                setEstadoVeiculo(options);
            });
            appApi.get("/vehicle_manufacturers").then(res => {
                
                 //@ts-ignore
                const options = res.data.data.map((item) => {
                    return {text: item.description, value: item.id}
                })   
                
                //@ts-ignore
                const arrayModel = res.data.data.map((item)=>{
                    return item
                })   
                
                setModeloVeiculo(arrayModel)
                setFabricanteVeiculo(options)
                
            });

            appApi.get('/vehicle_types').then((res)=>{
                const options = res.data.data.map((item: any)=>{
                    return {text: item.description, value: item.id}
                })
                setTipoVeiculo(options)
            })
        }
    }

    const CadastrarVeiculo = (event: any) => {
        event.preventDefault();

        const anttParams = {
            plate: formDataVehicle.plate,
            rntrc: formDataVehicle.rntrc
        }

        appApi.post('/vehicles/antt', anttParams).then((res)=>{
            if(res.status == 200){
                if(!isEdit && picture !== null){
                const formData = new FormData();
                //@ts-ignore
                formData.append("files", picture[0]);

                appApi.post(`/files`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    formDataVehicle.plate = formDataVehicle.plate.replace(/\-/, '');
                    let picture_id = res.data[0].id;
                    formDataVehicle.pictures = [picture_id];
            
                    appApi.post("/vehicles", formDataVehicle).then((response) => {
                    setModalAdicionaVeiculo(false);
                    handleNovoVeiculo(response.data)
                    toast.success(<span><MDBIcon icon="check" /> &nbsp; Veículo cadastrado com sucesso!</span>, {
                        closeButton: false,
                        autoclose: 5000
                    });
                    setFormDataVehicle({
                        plate: '',
                        renavam: '',
                        rntrc: '',
                        countryId: '',
                        stateId: '',
                        vehicleTypeId: '',
                        vehicleManufacturerId: '',
                        vehicleModelId: '',
                        vehicleYear:'',
                        vehicleDesc:''
                    });
                    })
                    .catch((error) => {
                        if (error.status === 500) {
                        toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte.</span>, {
                            closeButton: false,
                            autoclose: 5000
                        });
                        return;
                        }
                        toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro em algum campo do Formulário.</span>, {
                        closeButton: false,
                        autoclose: 5000
                        });
                    });
                }).catch((error: any) => {
                    toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro em algum campo do Formulário.</span>, {
                        closeButton: false,
                        autoclose: 5000
                        });
                })
                }
                if(isEdit){
                    //@ts-ignore
                    appApi.put("/vehicles/"+editInfo.id, formDataVehicle).then((response) => {
                        
                        setModalAdicionaVeiculo(false);
                        handleNovoVeiculo(response.data, true)
                        toast.success(<span><MDBIcon icon="check" /> &nbsp; Veículo atualizado com sucesso!</span>, {
                        closeButton: false,
                        autoclose: 5000
                        });
                        setFormDataVehicle({
                        plate: '',
                        renavam: '',
                        rntrc: '',
                        countryId: '',
                        stateId: '',
                        vehicleTypeId: '',
                        vehicleManufacturerId: '',
                        vehicleModelId: '',
                        vehicleYear:'',
                        vehicleDesc:''
                        });
                    })
                    .catch((error) => {
                        if (error.status === 500) {
                            toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte.</span>, {
                            closeButton: false,
                            autoclose: 5000
                            });
                            return;
                        }
                        toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro em algum campo do Formulário.</span>, {
                            closeButton: false,
                            autoclose: 5000
                        });
                    });        
                
                    
            }
        }
        }).catch((error)=>{
            if(error){
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Houve algum erro. Favor conferir os dados inseridos. </span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            }
        })
    }

    const handleFormChange = (event: any) => {
        const inputName = event.target.name;
        const newFormData = Object.assign({}, formDataVehicle);
        newFormData[inputName] = event.target.value;
        setFormDataVehicle(newFormData);
    }
    
    return (
        <>
            {!isEdit ? (
                    <MDBCol md="12" lg="2" xl="2">
                        <span className="botao-adicionar-novo-motorista">
                            <button onClick={toggleModalAdicionaVeiculo} className="waves-effect border-radius-7 w-100">
                                Adicionar
                            </button>
                        </span>
                    </MDBCol>
                ) : (

                    <MDBBtn onClick={toggleModalAdicionaVeiculo} id="btn-cadastro-veiculo" size="sm" className="background-azul btn">
                        Atualizar
                    </MDBBtn>
                )
            }
            <MDBModal id="cadastrar-veiculo" isOpen={modalAdicionaVeiculo} centered className="border-radius-adicionar-motorista">
                <MDBModalHeader toggle={toggleModalAdicionaVeiculo} className="background-azul white-text modal-header-h4">
                    {!isEdit ? 'Cadastrar Veículo' : 'Atualizar Veículo'}
                </MDBModalHeader>
                <MDBModalBody className="margins-laterais-15">
                    <div id="form-vehicle">
                    <form method={"post"} onSubmit={CadastrarVeiculo}>
                        <MDBRow id="inputs-mt-0">
                            <MDBCol md="6" lg="6" xl="6">
                                <div className="md-form">
                                    <label className="text-login-black company-cnpj-mask-label">Placa do Veículo</label>
                                    <InputMask
                                        onChange={handleFormChange}
                                        value={formDataVehicle.plate}
                                        className="form-control text-login-black vehicle-renavam-mask-input text-uppercase"
                                        name="plate"
                                        mask="aaa-9*99"
                                        required
                                        type="text"                   
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol md="6" lg="6" xl="6">
                                    <div className="md-form">
                                            <label className="text-login-black company-cnpj-mask-label">RNTRC</label>
                                            <InputMask
                                                onChange={handleFormChange}
                                                value={formDataVehicle.rntrc}
                                                className="form-control text-login-black vehicle-renavam-mask-input"
                                                name="rntrc"
                                                mask="999999999"
                                                required
                                                type="text"
                                                style={{width: "100%"}}
                                            />
                                    </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow id="inputs-mt-0">
                            <MDBCol md="6" lg="6" xl="6" >
                                <div className="md-form">
                                    <label className="text-login-black company-cnpj-mask-label">Renavam</label>
                                    <InputMask
                                        onChange={handleFormChange}
                                        value={formDataVehicle.renavam}
                                        className="form-control text-login-black vehicle-renavam-mask-input"
                                        mask="99999999999"
                                        name="renavam"
                                        required
                                        type="text"                   
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol md="6" lg="6" xl="6">
                                <div className="md-form">
                                    <label className="text-login-black company-cnpj-mask-label">Ano do Veículo</label>
                                    <InputMask
                                        onChange={handleFormChange}
                                        value={formDataVehicle.vehicleYear}
                                        className="form-control text-login-black vehicle-renavam-mask-input"
                                        mask="9999"
                                        name="vehicleYear"
                                        required
                                        type="text"                 
                                        style={{width: "100%"}}
                                    />
                                    </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                        <MDBCol md="6" lg="6" xl="6">
                            <select
                                onChange={handleFormChange}
                                value={formDataVehicle.countryId}
                                className="form-data text-login-black select-personalizado"
                                name="countryId"
                                required
                            >
                            <option selected={true} disabled={true} value='' className="text-login-black">País do Veículo</option>
                                {paisVeiculo.length && paisVeiculo.map((opcaoSelect) => {
                                    return (
                                    <option
                                        className="text-login-black"
                                        //@ts-ignore
                                        value={opcaoSelect.value}
                                    //@ts-ignore
                                    >{opcaoSelect.text}</option>
                                    );
                                })}
                            </select>
                        </MDBCol>
                        <MDBCol md="6" lg="6" xl="6">
                            <select
                            onChange={handleFormChange}
                            value={formDataVehicle.stateId}
                            className="form-data text-login-black select-personalizado"
                            name="stateId"
                            required
                            >
                            <option selected={true} disabled={true} value='' className="text-login-black">Estado do Veículo</option>
                            {estadoVeiculo.length && estadoVeiculo.map((opcaoSelect) => {
                                return (
                                <option
                                    className="text-login-black"
                                    //@ts-ignore
                                    value={opcaoSelect.value}
                                    //@ts-ignore
                                >{opcaoSelect.text}</option>
                                );
                            })}
                            </select>
                        </MDBCol>
                        </MDBRow>

                        <MDBRow>
                        <MDBCol md="6" lg="6" xl="6">
                            <select
                            onChange={handleFormChange}
                            value={formDataVehicle.vehicleTypeId}
                            className="form-data text-login-black select-personalizado"
                            name="vehicleTypeId"
                            required
                            >
                                <option selected={true} disabled={true} value='' className="text-login-black">Tipo do Veículo</option>
                                {/* @ts-ignore */}
                                {tipoVeiculo.map((opcaoSelect)=>{
                            
                                    return(
                                        <option
                                            className="text-login-black"
                                            //@ts-ignore
                                            value={opcaoSelect.value}
                                        >
                                            {/* @ts-ignore */}
                                            {opcaoSelect.text}
                                        </option>
                                    )
                                })}
                            </select>
                        </MDBCol>
                        <MDBCol md="6" lg="6" xl="6">
                            <select
                            //@ts-ignore
                            onChange={(e)=>{setFabricanteCadastro(e.target.value); handleFormChange(e)}}
                            value={formDataVehicle.vehicleManufacturerId}
                            className="form-data text-login-black select-personalizado"
                            name="vehicleManufacturerId"
                            required
                            >
                            <option selected={true} disabled={true} value='' className="text-login-black">Fabricante do Veículo</option>
                            {fabricanteVeiculo.map((opcaoSelect) => {
                                //@ts-ignore
                                return (
                                <option
                                    className="text-login-black"
                                    //@ts-ignore
                                    value={opcaoSelect.value}
                                    //@ts-ignore
                                >{opcaoSelect.text}</option>
                                );
                            })}
                            </select>
                        </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol md="6" lg="6" xl="6">
                                <select
                                onChange={handleFormChange}
                                value={formDataVehicle.vehicleModelId}
                                className="form-data text-login-black select-personalizado"
                                name="vehicleModelId"
                                required
                                >
                                <option selected={true} disabled={true} value='' className="text-login-black">Modelo do Veículo</option>
                                {modeloVeiculo.map((opcaoSelect: any) => {
                                    return opcaoSelect.models.map((modelo: any)=>{
                                        if(modelo.vehicle_manufacturer_id == fabricanteCadastro){
                                            return <option className="text-login-black" value={modelo.id}>{modelo.description}</option> 
                                        }
                                        
                                    })  
                            
                                })}
                                </select>
                            </MDBCol>

                            
                        <MDBCol md="6" lg="6" xl="12">
                                <label className="text-login-black" htmlFor="textareaAdicionaFrete">
                                    Observações do Veículo:
                                </label>
                                <textarea
                                    autoComplete="off" 
                                    onChange={handleFormChange}
                                    defaultValue={formDataVehicle.vehicleDesc}
                                    className="form-control text-login-black border-black"
                                    name="description"
                                    id="textareaAdicionaFrete"
                                    //@ts-ignore
                                    rows="4"
                                    style={{width: "100%"}}
                                    placeholder="Caso seu veículo não esteja especificado, favor inserir fabricante e modelo no campo acima."
                                />

                        </MDBCol>
                        
                        </MDBRow>
                        {!isEdit ? <MDBRow className="mt-3">
                            <MDBCol md="12" lg="12" xl="12" id="escolher-fotos-veiculo">
                                {/*<MDBFileInput multiple btnColor="dark" btnTitle="Escolher" textFieldTitle="Fotos do veículo" onChange={(e) => setPicture(e.target.files)}/>*/}
                                <label className="text-login-black company-cnpj-mask-label ">Foto do Veículo</label>
                                {/*@ts-ignore*/}
                                <input className="form-control mb-4" type="file" onChange={(e) => setPicture(e.target.files)} placeholder="Foto do veículo" 
                                    style={{width: "100%"}}/>
                            </MDBCol>
                        </MDBRow> : ''}
                        <div className="text-right">
                        <MDBBtn id="btn-cadastro-veiculo" size="sm" type={"submit"} className="background-azul btn">
                            Confirmar
                        </MDBBtn>
                        </div>
                    </form>
                    </div>
                </MDBModalBody>
            </MDBModal>
        </>
    )
}
export default FormAdicionar;