import '../styles/fretes.css';
import "react-image-gallery/styles/css/image-gallery.css";
import { useState, useEffect } from "react";
import {
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBtn,
} from "mdbreact";
import appApi from '../services/appApi';
import Layout from "./layout";
import ListaFretes from "../components/OutrosFretes/ListaFretes";
import DetalhesFrete from "../components/OutrosFretes/DetalhesFrete";
import Paginator from "../components/Paginator";
import Loading from "../components/Loading/loading";
import { IEstado } from '../types/estado.interface';
import { ICidade } from '../types/cidade.interface';
import { ITipoCarga } from '../types/tipo-carga.interface';
import { IFreight } from '../types/freights';


const OutrosFretes = () => {

    const [listaDeFretes, setListaDeFretes] = useState<IFreight[]>([]);
    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(0);
    const [idInfoFrete, setIdInfoFrete] = useState(null);

    const [filters, setFilters] = useState<IFilters[]>([]);

    const [estados, setEstados] = useState<IEstado[]>([]);
    const [tiposCarga, setTiposCarga] = useState<ITipoCarga[]>([])

    const [estadoSaida, setEstadoSaida] = useState('');
    const [estadoChegada, setEstadoChegada] = useState('');
    const [cidadeSaida, setCidadeSaida] = useState('');
    const [cidadeChegada, setCidadeChegada] = useState('');

    interface IFilters {
        field: string
        operator: string
        param: string
    }

    useEffect(() => {
        appApi.get("/states").then(res => {
            setEstados(res.data.data);
        });
        appApi.get("/cargo_types").then(res => {
            setTiposCarga(res.data.data);
        });
    }, [])

    useEffect(() => {
        appApi.post("/freights/filter", {
            filter_join: "AND",
            filters: filters,
            page: page
        }).then(res => {
            setListaDeFretes(res.data.data);
            const qt = res.data.meta.total / res.data.meta.per_page;
            const resto = res.data.meta.total % res.data.meta.per_page;
            setQuantity(qt + (resto > 0 ? 1 : 0));
        });
    }, [page, filters])

    const handleSetFilters = (e: any) => {
        if (filters.find((item) => item.field === e.target.name) === undefined){
            setFilters(filters.concat(createFilter(e)));
        } else {
            setFilters(filters.map((item) => {
                if(item.field === e.target.name) {
                    item = createFilter(e);
                }
                return item;
            }));
        }
    }

    const createFilter = (e: any) => {
        return {
            field: e.target.name, 
            operator: 'equals',
            param: e.target.value
        }
    }

    const cleanFilters = () => {
        setFilters([]);
        setEstadoSaida('');
        setEstadoChegada('');
        setCidadeSaida('');
        setCidadeChegada('');
    }

    return (
        <Layout>
            {
                idInfoFrete === null ? (
                    <>
                        <MDBRow id="pagina-veiculos">
                            <MDBCol md="12" lg="12" xl="12" className="col-lg-motoristas">
                                <MDBRow>
                                    <MDBCol md="12" lg="12" xl="12" className="col-lg-motorista text-left">
                                        <button className="waves-effect botao-adicionar-listar-motoristas font-size-17 mt-1 ml-4">
                                            <h3 className="font-weight-bold color-azul"><MDBIcon icon="money-bill-alt"/> Espaços Disponíveis em Veículos</h3>
                                        </button>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-2">
                            <MDBCol md="6" lg="6" xl="3">
                                <select
                                    onChange={(e) => {
                                        setEstadoSaida(e.target.value);
                                    }}
                                    value={estadoSaida}
                                    name="estado-saida"
                                    className="form-data text-login-black select-personalizado cursor-pointer"
                                    required
                                >
                                    <option selected={true} disabled={true} value='' className="text-login-black">Estado de Origem</option>
                                    {estados.map((estado: IEstado) => (
                                        <option key={estado.id} className="text-login-black" value={estado.id}>{estado.description}</option>
                                    ))}
                                </select>
                            </MDBCol>
                            <MDBCol md="6" lg="6" xl="3">
                                <select
                                    onChange={(e) => {
                                        handleSetFilters(e);
                                        setCidadeSaida(e.target.value);
                                    }}
                                    value={cidadeSaida}
                                    name="origin_city"
                                    className="form-data text-login-black select-personalizado cursor-pointer"
                                    required
                                >
                                    <option selected={true} disabled={true} value='' className="text-login-black">Cidade de Origem</option>
                                    {
                                        estados.map((estado: IEstado) => {
                                            if(estado.id == Number(estadoSaida)){
                                                return estado.cities.map((cidade: ICidade) => (
                                                    <option key={cidade.id} className="text-login-black" value={cidade.id}>{cidade.description}</option>
                                                ))
                                            }
                                        })
                                    }
                                </select>
                            </MDBCol>
                            <MDBCol md="6" lg="6" xl="3">
                                <select
                                    onChange={(e) => {
                                        setEstadoChegada(e.target.value);
                                    }}
                                    value={estadoChegada}
                                    name="estado-saida"
                                    className="form-data text-login-black select-personalizado cursor-pointer"
                                    required
                                >
                                    <option selected={true} disabled={true} value='' className="text-login-black">Estado de Destino</option>
                                    {estados.map((estado: IEstado) => (
                                        <option key={estado.id} className="text-login-black" value={estado.id}>{estado.description}</option>
                                    ))}
                                </select>
                            </MDBCol>
                            <MDBCol md="6" lg="6" xl="3">
                                <select
                                    onChange={(e) => {
                                        handleSetFilters(e);
                                        setCidadeChegada(e.target.value);
                                    }}
                                    value={cidadeChegada}
                                    name="destination_city"
                                    className="form-data text-login-black select-personalizado cursor-pointer"
                                    required
                                >
                                    <option selected={true} disabled={true} value='' className="text-login-black">Cidade de Destino</option>
                                    {
                                        estados.map((estado: IEstado) => {
                                            if(estado.id == Number(estadoChegada)){
                                                return estado.cities.map((cidade: ICidade) => (
                                                    <option key={cidade.id} className="text-login-black" value={cidade.id}>{cidade.description}</option>
                                                ))
                                            }
                                        })
                                    }
                                </select>
                            </MDBCol>
                            <MDBCol md="6" lg="6" xl="3">
                                <input
                                    className="select-with-search"
                                    type="date"
                                    name="date"
                                    onChange={handleSetFilters}
                                    style={{width: "100%", border: "0", borderBottom: "1px solid #999", marginTop: "13px"}}
                                />
                            </MDBCol>
                            <MDBCol md="6" lg="6" xl="3">
                                <select
                                    onChange={handleSetFilters}
                                    name="cargo_type"
                                    className="form-data text-login-black select-personalizado cursor-pointer"
                                    required
                                >
                                    <option selected={true} disabled={true} className="text-login-black">Tipo de Carga</option>
                                    {tiposCarga.map((tipo: ITipoCarga) => (
                                        <option key={tipo.id} className="text-login-black" value={tipo.id}>{tipo.description}</option>
                                    ))}
                                </select>
                            </MDBCol>      
                            <MDBBtn id="btn-cadastro-veiculo" style={{height: "40px"}} size="sm" type="button" onClick={cleanFilters} className="background-azul btn white-text">
                                Limpar Filtros
                            </MDBBtn>                  
                        </MDBRow>
                            {listaDeFretes.length <= 0 ? <Loading name="espaços disponíveis" /> :     
                                <ListaFretes listaDeFretes={listaDeFretes} setIdInfoFrete={setIdInfoFrete} />
                            }
                        <Paginator quantity={quantity} onSelect={setPage} />
                    </>
                ) : (
                    <DetalhesFrete idFrete={idInfoFrete} botaoVoltar={() => setIdInfoFrete(null)}/>
                )
            }
            
        </Layout>
    )

}

export default OutrosFretes;