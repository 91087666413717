import { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBMask,
    MDBInput,
    MDBView,
    MDBBtn,
    MDBIcon,
    //@ts-ignore
    toast,
} from 'mdbreact';
import logoColorida from '../../images/logoColorida.svg';
import appApi from '../../services/appApi';


const EsqueciSenha = () => {

    const [ email, setEmail ] = useState<string>('')

    const enviarEmail = (event: any) => {
        event.preventDefault()
        appApi.post("/forgot_password", { email }).then((response) => {
            if (response.status === 200) {
                toast.success(<span><MDBIcon icon="check" /> &nbsp; Email enviado!</span>, {
                    closeButton: false
                });
            }
        })
        .catch((error) => {
            if (error.response.status == 400) {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Email não encontrado! Favor verificar.</span>, {
                    closeButton: false
                });
            } else {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Houve algum erro com o servidor. Por favor, tente mais tarde.</span>, {
                    closeButton: false
                });
            }
        })
    }


    return (
        <MDBView>
            <MDBMask>
                <div id="div-fundo">
                    <div id="fundo-login-cadastro">
                        <div id="login-cadastro">
                            <MDBContainer>
                                <MDBRow>
                                    <div className="text-center w-100 mt-5 mb-5">
                                        <a href="/">
                                            <img src={logoColorida} width="300px" className="d-inline-block" alt="Logo Share Economy" />
                                        </a>
                                    </div>
                                </MDBRow>
                                <MDBRow id="forms-login-cadastro" className="w-100 mb-5">
                                    <MDBCol className="mt-5 mx-auto" md="10" lg="6" xl="5" sm="12">
                                        <MDBCard className="card-image background-amarelo border-radius-20">
                                            <div className="text-white py-5 px-5 z-depth-4" id="form-login">
                                                <div className="text-center">
                                                    <h4 className="text-login mt-4 font-weight-bold">
                                                        <strong>Recuperação de senha</strong>
                                                    </h4>
                                                    <p className="text-login mb-5 email-redefinicao-senha">
                                                        Por favor insira o email cadastrado abaixo, logo em seguida,
                                                        um email será enviado para você redefinir a sua senha.
                                                    </p>
                                                    <form>
                                                        <MDBInput
                                                            className="form-control text-login"
                                                            label="Seu Email"
                                                            name="emailLogin"
                                                            required
                                                            type="email"
                                                            labelClass="text-login"
                                                            style={{ width: "100%" }}
                                                            onChange={(e: any) => setEmail(e.target.value)}
                                                        />
                                                        <MDBBtn className="background-azul" rounded onClick={enviarEmail}>Enviar email</MDBBtn>
                                                    </form>
                                                </div>
                                            </div>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </div>
                    </div>
                </div>
            </MDBMask>
        </MDBView>
    )
}

export default EsqueciSenha