import axios from "axios";
import { getToken } from "../pages/auth";

const appApi = axios.create({
  baseURL: "https://api.shareeconomy.com.br",
  //baseURL: 'http://localhost:3333'
});

appApi.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default appApi;
