import { useContext, useEffect, useState } from 'react'
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBIcon,
    //@ts-ignore
    ToastContainer,
    //@ts-ignore
    toast,
} from "mdbreact";
import Loading from '../Loading/loading';
import { getDataFormatada } from '../../services/utils';
import { TrackContext } from '../../services/context';
import ListaTrajetos from './listaTrajetos';
import appApi from '../../services/appApi';

const Detalhes = () => {
    

    const { detailsInfo, setShowDetails } = useContext(TrackContext)
    
    const [ locationList, setLocationList ] = useState([])

    useEffect(() => {
        appApi.get(`/freight_matches/location/${detailsInfo.id}`,).then((response) => {
            setLocationList(response.data)
        }).catch((error) => {
            if(error.response.status === 404){
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Ainda não há localizações disponíveis.</span>, {
                    closeButton: false
                });
            } else {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Houve algum erro. Tente novamente mais tarde.</span>, {
                    closeButton: false
                });
            }
        })
    }, []) 

    return (
        <>
            <ToastContainer
                hideProgressBar
                newestOnTop
                autoClose={5000}
            />
            <div id="detalhes-frete">
            <MDBRow className="mb-3">
                <button className="button-go-back" onClick={() => setShowDetails(false)}>
                    <MDBIcon icon="arrow-left" />&nbsp;
                    Voltar
                </button>
            </MDBRow>

            {!detailsInfo ? <Loading name='detalhes do espaço'/> :
                <section className="mt-4 mb-5">
                    <h3 className="font-weight-bold text-left mt-1 mb-3">
                        Detalhes do Espaço
                    </h3>
                    
                    <strong>
                        <span>Status: <i>{detailsInfo.freight.freightStatus.description}</i></span>
                    </strong>
                    <MDBRow>
                        <MDBCol lg="6">
                            <form>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.height}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Altura disponível"
                                            name="height"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.width}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Largura disponível"
                                            name="width"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow id="inputs-mt-0">
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.depth}
                                            className="form-control color-disabled"
                                            disabled={true}
                                            label="Profundidade disponível"
                                            name="depth"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.volume}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Espaço em PBR"
                                            name="volume"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow id="inputs-mt-0">
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.price}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Preço"
                                            name="price"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            
                                            value={detailsInfo.freight.weight}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Peso"
                                            name="weight"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow id="inputs-mt-0">
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.vehicle.plate}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Veículo"
                                            name="vehicle"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.driver_id}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Motorista"
                                            name="driver"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.originState.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Estado de origem"
                                            name="originState"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.originCity.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Cidade de origem"
                                            name="originCity"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.destinationState.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Estado destino"
                                            name="destinationState"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.destinationCity.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Cidade destino"
                                            name="destinationCity"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.cargoType.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Tipo de carga"
                                            name="cargoType"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.carrierType.description}
                                            className={"form-control color-disabled"}
                                            disabled={true}
                                            label="Tipo de transporte"
                                            name="carrierType"
                                            required
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow id="inputs-mt-0">
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={getDataFormatada(detailsInfo.freight.freight_date)}
                                            className="form-control color-disabled"
                                            label="Data de partida"
                                            disabled={true}
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput
                                            value={detailsInfo.freight.time}
                                            className="form-control color-disabled"
                                            label="Horário de partida"
                                            disabled={true}
                                            type="text"
                                            labelClass="text-login"
                                            style={{ width: "100%" }}
                                            required
                                        />
                                    </MDBCol>
                                    
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput 
                                            type="textarea" 
                                            className="form-control color-disabled"
                                            label="Descrição do Veículo" 
                                            rows="3"
                                            style={{width: "100%"}}
                                            disabled={true}
                                            value={detailsInfo.freight.vehicle.vehicle_desc ?? ''}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6" lg="6" xl="6">
                                        <MDBInput 
                                            type="textarea" 
                                            className="form-control color-disabled"
                                            label="Descrição do Espaço" 
                                            rows="3"
                                            style={{width: "100%"}}
                                            disabled={true}
                                            value={detailsInfo.freight.description ?? ''}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </form>
                        </MDBCol>
                        <MDBCol>
                            <ListaTrajetos locationList={locationList}/>
                        </MDBCol>
                    </MDBRow>
                </section>}
        </div>
        </>
    )
}

export default Detalhes