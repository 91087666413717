import { MDBToastContainer as ToastContainer } from 'mdbreact';
import Navbar from '../components/Navbar'

//@ts-ignore
export default ({ children }) => {

  return (
    <div>
      <Navbar>
        {children}
        <ToastContainer
          hideProgressBar
          newestOnTop
          autoClose={5000}
        />
      </Navbar>
    </div>
  );
};
