import { Dispatch, SetStateAction, useState } from "react";
import {
    //@ts-ignore
    toast,
    MDBCol,
    MDBBtn,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBAnimation,
} from 'mdbreact';
import { getDataFormatada } from '../../../services/utils'
import appApi from "../../../services/appApi";
import { IMotorista } from "../../../types/motorista.interface";

interface IProps {
    listaDeMotoristas: IMotorista[]
    handleExcluirMotorista: Function
    setIdInfoMotorista: Dispatch<SetStateAction<number | null>>
}

const ListaMotoristas = ({listaDeMotoristas, handleExcluirMotorista, setIdInfoMotorista}: IProps) => {

    const [confirmarExcluirMotorista, setConfirmarExcluirMotorista] = useState(false);
    const [motoristaSelecionadoParaExclusao, setMotoristaSelecionadoParaExclusao] = useState<number | null>(null);

    const toggleConfirmarExcluirMotorista = (idDoMotorista: number | null) => {
        setMotoristaSelecionadoParaExclusao(idDoMotorista);
        setConfirmarExcluirMotorista(!confirmarExcluirMotorista);
    }

    const ExcluirMotorista = (event: any) => {
        event.preventDefault();

        if (!motoristaSelecionadoParaExclusao) {
            return;
        }
        appApi.delete(`/drivers/${motoristaSelecionadoParaExclusao}`).then((response) => {
            
            setConfirmarExcluirMotorista(false);
            toast.success(<span><MDBIcon icon="check" /> &nbsp; Motorista excluído com êxito!</span>, {
                closeButton: false,
                autoclose: 5000
            });

            setMotoristaSelecionadoParaExclusao(null);
            handleExcluirMotorista(motoristaSelecionadoParaExclusao);

        })
        .catch((error) => {
            if(error.response.status === 422){
                toast.error(<span><MDBIcon icon="times" /> &nbsp;  Motorista possui espaços vinculados!</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            } else {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Houve algum erro. Tente novamente mais tarde.</span>, {
                    closeButton: false,
                    autoclose: 5000
                });
            }            
        });
    }

    return (
        <>
            <MDBAnimation type="fadeIn">
                <div id="section-listar-motoristas">
                    <MDBCol md="12" className="mt-2 pr-0 pl-0">
                        <MDBTable className="mb-5" responsive>
                            <MDBTableHead color="background-azul" textWhite>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>CNH</th>
                                    <th>Vencimento da CNH</th>
                                    <th>Informações</th>
                                    <th>Ações</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                
                                {
                                listaDeMotoristas.length > 0 && listaDeMotoristas.map((motorista) => {
                                    return (
                                        <tr className="table-body-hover" id={"motorista" + motorista.id} key={motorista.id}>
                                            <td>{motorista.id}</td>
                                            <td>{motorista.name}</td>
                                            <td>{motorista.cnh}</td>
                                            <td>{getDataFormatada(motorista.expire_date)}</td>
                                            <td>
                                                <a onClick={() => setIdInfoMotorista(motorista.id)}>
                                                    <MDBIcon icon="info" className="d-inline-inline -10" />
                                                &nbsp; Info 
                                            </a>
                                            </td>
                                            <td>
                                                <a onClick={() => toggleConfirmarExcluirMotorista(motorista.id)}>
                                                    <MDBIcon icon="times" className="d-inline-inline -10 text-danger" />
                                                    &nbsp; Excluir
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </div>
            </MDBAnimation>
            <MDBModal id="excluir-motorista" isOpen={confirmarExcluirMotorista} centered className="border-radius-adicionar-motorista">
                <MDBModalHeader className="background-azul white-text modal-header-h4 text-align-center d-inline-block">
                    Tem certeza que deseja excluir o motorista?
                </MDBModalHeader>
                <MDBModalBody className="margins-laterais-15 text-align-center">
                    <p>
                        <MDBIcon icon="exclamation-triangle" style={{color: "#FFBB33"}} />&nbsp; 
                        Esta ação <strong>não</strong> poderá ser desfeita!
                    </p>
                    {/*@ts-ignore*/}
                    <MDBBtn onClick={ExcluirMotorista} id="btn-excluir-veiculo" size="md" type={"submit"} className="background-azul btn white-text">
                        <MDBIcon icon="check" />&nbsp;
                        Confirmar
                    </MDBBtn>
                    {/*@ts-ignore*/}
                    <MDBBtn onClick={() => toggleConfirmarExcluirMotorista(null)} id="btn-cancelar-exclusao-veiculo" size="md" type={"submit"} className="background-azul btn white-text">
                        <MDBIcon icon="times" />&nbsp;
                        Cancelar
                    </MDBBtn>
                </MDBModalBody>
            </MDBModal>
        </>
    )

}

export default ListaMotoristas;