import React from "react";
import Routes from './routes/routes';
import { GlobalStyle } from "./styles/global";


export const App = () => (
  <>

    <Routes />
    <GlobalStyle />
  </>
)
