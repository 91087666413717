import { useEffect, useState } from 'react'
import { MDBIcon } from 'mdbreact'
import '../../styles/loading.css'; 

interface LoadingProps {
    name: string
}

const Loading = ({ name }:LoadingProps) => {

    const [loading, setLoading ] = useState(true)

    setTimeout(()=>{    
        setLoading(false)
    }, 7000)

    const reloadPage = () => {
        window.location.reload()
    }

    return (
        <>
            {loading ? (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className='loader'></div>
                                </div>) 
                    : 
                (<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <div className='modal-loading'>
                        <div>
                            <h5>Não foi possível carregar {name}.</h5>    
                        </div>
                        <div style={{ cursor: 'pointer' }}>
                            <h6 onClick={reloadPage}>
                                <b>Tente recarregar a página</b>
                                &nbsp;
                                <MDBIcon icon='redo' size='1x'/>
                            </h6>
                        </div>
                    </div>
                </div>)
            }
        </>
        
        
    )
}

export default Loading;