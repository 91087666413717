import '../../styles/cadastro.css';
import { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBMask,
    MDBInput,
    MDBView,
    MDBBtn,
    MDBIcon,
    //@ts-ignore
    toast,
} from 'mdbreact';
import appApi from '../../services/appApi';
import InputMask from 'react-input-mask';
import logoColorida from '../../images/logoColorida.svg';
import { IEstado } from '../../types/estado.interface';
import { ICidade } from '../../types/cidade.interface';

const Cadastro = ({ toggleParteLogin }: any) => {

    interface IFormData {
        cnpj: string
        stateId: string
        cityId: string
        phone: string
        name: string
        email: string
        password: string
        passwordConfirm: string
    }

    type IInputName = 'cnpj' | 'stateId' | 'cityId' | 'phone' | 'name' | 'email' | 'password' | 'passwordConfirm'

    const [formData, setFormData] = useState<IFormData>({} as IFormData);

    const [estados, setEstados] = useState([]);
    const [estadoCadastro, setEstadoCadastro] = useState('');
    
    useEffect(() => {
        appApi.get("/states").then(response => {
            setEstados(response.data.data);
        });
    }, [])


    const handleFormChange = (event: any) => {
        const inputName: IInputName = event.target.name;
        formData[inputName] = event.target.value;
        setFormData(formData);
        console.log(formData)
    }


    const adicionarEmpresas = (event: any) => {
        event.preventDefault();

        formData.cnpj = formData.cnpj.replace(/[^\d]+/g,'')
        formData.phone = formData.phone.replace(/[^+\d]+/g, "") 
        appApi.post("/register", formData).then((response) => {
            if (response.status === 200) {
                toast.success(<span><MDBIcon icon="check" /> &nbsp; Usuário cadastrado com sucesso</span>, {
                    closeButton: false
                });
            }
        }).catch((error) => {
            if (error.response.status === 400) {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro em algum campo do Formulário</span>, {
                    closeButton: false
                });
            }
            if (error.response.status === 422) {               
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Erro no campo CNPJ ou Senha!</span>, {
                    closeButton: false
                });
            }
            else {
                toast.error(<span><MDBIcon icon="times" /> &nbsp; Algo inesperado aconteceu, favor contatar o suporte</span>, {
                    closeButton: false
                });
            }            
        });
    };

    return (
        <div id="parte-cadastro">
            <MDBView id="parte-autenticacao">
                <MDBMask>
                    <div id="div-fundo">
                        <div id="fundo-login-cadastro">
                            <div id="login-cadastro">
                                <MDBContainer>
                                    <MDBRow>
                                        <div className="text-center w-100 mt-5 mb-5">
                                            <a href="/">
                                                <img src={logoColorida} width="300px" className="d-inline-block" alt="Logo Share Economy" />
                                            </a>
                                        </div>
                                    </MDBRow>
                                    <MDBRow id="forms-login-cadastro" className="w-100 mb-5">
                                        <MDBCol className="mt-5 mx-auto" md="10" lg="6" xl="5" sm="12">
                                            <MDBCard className="card-image background-azul border-radius-20">
                                                <div className="text-white py-5 px-5 z-depth-4" id="form-cadastro">
                                                    <div className="text-center">
                                                        <h3 className="white-text mb-5 mt-4 font-weight-bold">
                                                            <strong>Cadastrar Empresa</strong>
                                                        </h3>
                                                        <form method={"post"} onSubmit={adicionarEmpresas}>
                                                            <MDBInput
                                                                onChange={handleFormChange}
                                                                className="form-control white-text"
                                                                label="Nome da Empresa"
                                                                name="name"
                                                                required
                                                                type="text"
                                                                labelClass="white-text"
                                                                style={{ width: "100%", fontWeight: "300" }}
                                                                autoComplete="off"
                                                            />
                                                            <MDBInput
                                                                onChange={handleFormChange}
                                                                className="form-control white-text"
                                                                label="Email"
                                                                name="email"
                                                                required
                                                                type="email"
                                                                labelClass="white-text"
                                                                style={{ width: "100%", fontWeight: "300" }}
                                                                autoComplete="off"
                                                            />
                                                            <div className="md-form">
                                                                <label className="white-text company-cnpj-mask-label">CNPJ</label>
                                                                <InputMask
                                                                    onChange={handleFormChange}
                                                                    className="form-control white-text company-cnpj-mask-input"
                                                                    mask="99.999.999/9999-99"
                                                                    name="cnpj"
                                                                    required
                                                                    type="text"
                                                                    placeholder="XX.XXX.XXX/XXXX-XX"
                                                                    style={{ width: "100%" }}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className="md-form">
                                                                <label className="white-text company-cel-mask-label">Celular</label>
                                                                <InputMask
                                                                    onChange={handleFormChange}
                                                                    className="white-text company-cel-mask-input"
                                                                    mask="(99)99999-9999"
                                                                    name="phone"
                                                                    required
                                                                    type="text"
                                                                    placeholder="(XX)XXXXX-XXXXX"
                                                                    style={{ width: "100%" }}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <select
                                                                onChange={(e) => {
                                                                    setEstadoCadastro(e.target.value)
                                                                    handleFormChange(e)
                                                                }}
                                                                name="stateId"
                                                                className="text-login-white select-cadastro-empresa"
                                                                required
                                                                style={{ width: "101%", backgroundColor: "#272E63", border: 0, borderBottom: "1px solid #FFF", marginBottom: "40px", marginTop: "20px", outline: "none" }}
                                                            >
                                                                <option selected={true} disabled={true} className="text-login-black">Estado</option>
                                                                {estados.map((estado: IEstado) => (
                                                                    <option className="text-login-white" key={estado.id} value={estado.id}>{estado.description}</option>
                                                                ))}
                                                            </select>
                                                            <select
                                                                onChange={handleFormChange}
                                                                name="cityId"
                                                                className="text-login-white select-cadastro-empresa"
                                                                style={{ width: "101%", backgroundColor: "#272E63", border: 0, borderBottom: "1px solid #FFF", outline: "none" }}
                                                                required
                                                            >
                                                                <option selected={true} disabled={true} className="text-login-black">Cidade</option>
                                                                {estados.map((estado: IEstado) => {
                                                                    if(estado.id === Number(formData.stateId)){
                                                                        return estado.cities.map((cidade: ICidade) => (
                                                                            <option className="text-login-white" key={cidade.id} value={cidade.id}>{cidade.description}</option>
                                                                        ))
                                                                    }
                                                                })}
                                                            </select>
                                                            <MDBInput
                                                                onChange={handleFormChange}
                                                                className="form-control white-text"
                                                                label="Sua senha"
                                                                name="password"
                                                                required
                                                                type="password"
                                                                labelClass="text-label"
                                                                style={{ width: "100%", marginTop: "15px", fontWeight: "300" }}
                                                                autoComplete="off"
                                                            />
                                                            <p
                                                                className='obs'
                                                            >*A senha deve possuir pelo menos 8 caracteres. Incluindo uma letra maiúscula, uma minúscula e um dígito.</p>
                                                            <MDBInput
                                                                onChange={handleFormChange}
                                                                className="form-control white-text"
                                                                label="Confirme sua senha"
                                                                name="passwordConfirm"
                                                                required
                                                                type="password"
                                                                labelClass="white-text"
                                                                style={{ width: "100%", fontWeight: "300" }}
                                                                autoComplete="off"
                                                            />
                                                            <MDBBtn type={"submit"} className="Btn-Cadastro" rounded>Cadastrar</MDBBtn>
                                                        </form>
                                                        <p className="botao-ja-tem-login">
                                                            Já tem login?&nbsp; <a id="botao-login" onClick={toggleParteLogin}>Entrar</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </div>
                        </div>
                    </div>
                </MDBMask>
            </MDBView>
        </div>
    )
}

export default Cadastro