    import  {useState, useEffect} from 'react';
import {
    MDBIcon,
    MDBRow,
    MDBCol,
} from "mdbreact";
import '../styles/fretes.css';
import appApi from '../services/appApi';
import Resumo from '../components/MeusFretes/Resumo';
import ListaFretes from '../components/MeusFretes/ListaFretes';
import Layout from "./layout";
import FormAdicionar from '../components/MeusFretes/FormAdicionar';
import DetalhesFrete from '../components/MeusFretes/DetalhesFrete';
import Paginator from "../components/Paginator";
import Loading from '../components/Loading/loading';

const MeusFretes = () => {

    const [listaDeFretes, setListaDeFretes] = useState([]);
    const [idInfoFrete, setIdInfoFrete] = useState(null);
    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        appApi.get("/freights/me?page=" + page).then(res => {
            setListaDeFretes(res.data.data);
            const qt = res.data.meta.total / res.data.meta.per_page;
            const resto = res.data.meta.total % res.data.meta.per_page;
            setQuantity(qt + (resto > 0 ? 1 : 0));
        });
    }, [page]);

    const handleNovoFrete = (novoFrete: any) => {
        const novaListaDeFretes = listaDeFretes.concat(novoFrete);
        setListaDeFretes(novaListaDeFretes);
    }

    const handleExcluirFrete = (idFrete: number) => {
        const fretesAtualizados = listaDeFretes.filter((frete) => {
            //@ts-ignore
            return frete.id !== idFrete;
        });

        setListaDeFretes(fretesAtualizados);
    }

    return (
        <Layout>
            {   
            idInfoFrete === null ? (
                <>
                    <MDBRow id="pagina-veiculos">
                        <MDBCol md="12" lg="12" xl="12" className="col-lg-motoristas">
                            <MDBRow>
                                <MDBCol md="12" lg="10" xl="10" className="col-lg-motorista text-left">
                                    <button 
                                        className="waves-effect botao-adicionar-listar-motoristas font-size-17 mt-1 ml-4"
                                        //onClick={toggleMeusFretes} 
                                        //style={{ borderLeft: listagemFretes || detalhesFrete ? '2px solid #272E623' : '' }} 
                                    >
                                        <h3 className="font-weight-bold color-azul"><MDBIcon icon="truck-loading"/> Meus Espaços</h3>
                                    </button>
                                </MDBCol>
                                {/*@ts-ignore*/}
                                <FormAdicionar handleNovoFrete={handleNovoFrete} />
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <div id="meus-fretes">
                        <Resumo todos={listaDeFretes.length}/>
                        {listaDeFretes.length === 0 ? <Loading name="Meus Espaços"/> : 
                            <ListaFretes 
                                listaDeFretes={listaDeFretes}
                                handleExcluirFrete={handleExcluirFrete}
                                setIdInfoFrete={setIdInfoFrete}
                            />
                        }
                    <Paginator quantity={quantity} onSelect={setPage} />                        
                    </div>
                </>
            ) : (
                <DetalhesFrete idFrete={idInfoFrete} botaoVoltar={() => setIdInfoFrete(null)}/>
            )
            }
        </Layout>
    )
}

export default MeusFretes;
