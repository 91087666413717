import { useState } from 'react';
import {
    //@ts-ignore
    ToastContainer,
} from 'mdbreact';
import Login from '../components/TelaInicial/login'
import Cadastro from '../components/TelaInicial/cadastro';
import EsqueciSenha from '../components/TelaInicial/esqueciSenha';

const Autenticacao = () => {

    const token = "@shareeconomy_token"
    const [mostraParteLogin, setMostraParteLogin] = useState(true);
    const [mostraParteCadastro, setMostraParteCadastro] = useState(false);
    const [mostraEsqueciMinhaSenha, setMostraEsqueciMinhaSenha] = useState(false);

    const toggleParteLogin = () => {
        if (mostraParteLogin) {
            return;
        }
        setMostraParteLogin(true);
        setMostraParteCadastro(false);
        setMostraEsqueciMinhaSenha(false);
    }

    const toggleParteCadastro = () => {
        if (mostraParteCadastro) {
            return;
        }
        setMostraParteCadastro(true);
        setMostraParteLogin(false);
    }

    const toggleParteEsqueciMinhaSenha = () => {
        if (mostraEsqueciMinhaSenha) {
            return;
        }
        setMostraEsqueciMinhaSenha(true);
        setMostraParteLogin(false);
    }


    document.addEventListener('DOMContentLoaded', () => {
        let tokenCompare = sessionStorage.getItem(token) != ''
        if (tokenCompare) {
            sessionStorage.removeItem(token)
        }
    })

    return (
        <div id="partes-login-cadastro">
            <ToastContainer
                hideProgressBar
                newestOnTop
                autoClose={5000}
            />
            {mostraParteLogin && <Login toggleParteEsqueciMinhaSenha={toggleParteEsqueciMinhaSenha} toggleParteCadastro={toggleParteCadastro} />}
            {mostraEsqueciMinhaSenha && <EsqueciSenha />}
            {mostraParteCadastro && <Cadastro toggleParteLogin={toggleParteLogin}/>}
        </div>
    );
}

export default Autenticacao;