import  { useState, useEffect } from "react";
import {
    MDBIcon,
    MDBRow,
    MDBCol, 
  } from 'mdbreact';
import Layout from "./layout";
import '../styles/motoristas.css'; 
import appApi from "../services/appApi";
import DetalhesMotorista from "../components/MeusMotoristas/DetalhesMotorista";
import ListaMotoristas from "../components/MeusMotoristas/ListaMotoristas";
import FormAdicionar from "../components/MeusMotoristas/FormAdicionar";
import Paginator from "../components/Paginator";
import Loading from "../components/Loading/loading";
import { IMotorista } from "../types/motorista.interface";

const MeusMotoristas = () => {

    const [listaDeMotoristas, setListaDeMotoristas] = useState<IMotorista[]>([]);
    const [idInfoMotorista, setIdInfoMotorista] = useState<number | null>(null);
    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(0);


    useEffect(() => {
        appApi.get("/drivers?page="+page).then(res => {
            setListaDeMotoristas(res.data.data);
            const qt = res.data.meta.total / res.data.meta.per_page;
            const resto = res.data.meta.total % res.data.meta.per_page;
            setQuantity(qt + (resto > 0 ? 1 : 0));
        });
    }, [page]);

    const handleNovoMotorista = (novoMotorista: any, isEdit = false) => {
        if(isEdit){
            const novaListaDeMotoristas = listaDeMotoristas.map((item) => {
                if(item.id === novoMotorista.id){
                    item = novoMotorista
                }
                return item
            })
            setListaDeMotoristas(novaListaDeMotoristas);
        }else{
            const novaListaDeMotoristas = listaDeMotoristas.concat(novoMotorista);
            setListaDeMotoristas(novaListaDeMotoristas);
        }     
    }

    const handleExcluirMotorista = (idMotorista: number) => {
        const motoristasAtualizados = listaDeMotoristas.filter((motorista) => {
            return motorista.id !== idMotorista
        });
        setListaDeMotoristas(motoristasAtualizados);
    }

    return (
        <Layout>
            {!idInfoMotorista ? (
                <>
                    <MDBRow id="pagina-veiculos" style={{marginBottom: "40px", width: "100%"}}>
                        <MDBCol md="12" lg="12" xl="12">
                            <MDBRow>
                                <MDBCol md="12" lg="8" xl="8" className="text-left">
                                    <h3 className="font-weight-bold color-azul"><MDBIcon icon="id-card" /> Motoristas</h3>
                                    
                                </MDBCol>
                                <FormAdicionar handleNovoMotorista={handleNovoMotorista} />
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    {listaDeMotoristas.length <= 0 ? <Loading name="meus motoristas"/> : 
                        <ListaMotoristas listaDeMotoristas={listaDeMotoristas} handleExcluirMotorista={handleExcluirMotorista} setIdInfoMotorista={setIdInfoMotorista}/>
                    }
                    <Paginator quantity={quantity} onSelect={setPage} />
                    
                </>
            ) : (
                <DetalhesMotorista idMotorista={idInfoMotorista} handleNovoMotorista={handleNovoMotorista} botaoVoltar={() => setIdInfoMotorista(null)}/>
            )}
        </Layout>
    )

}

export default MeusMotoristas;