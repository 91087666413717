import {useState, useEffect, createContext, useContext} from 'react'
import appApi from "../services/appApi";
import io from 'socket.io-client';

const ChatContext = createContext();

const BASE_URL = `https://api.shareeconomy.com.br`

const socket = io.connect(BASE_URL, {
    extraHeaders: {
        "Authorization": "Bearer "+ sessionStorage.getItem('@shareeconomy_token')
    }
})

export const useChat = () => {
    return useContext(ChatContext);
}

export const ChatProvider = ({ children }) => {

    const [mineChats, setMineChats] = useState([]);
    const [otherChats, setOtherChats] = useState([]);
    const [selectedChatData, setSelectedChatData] = useState();
    const [mine, setMine] = useState(false);

    useEffect(() => {
        requestChatsData();
    }, [mine]);

    useEffect(() => {
        if(selectedChatData !== undefined){
            handleSelectedChat(selectedChatData.id);
        }
    }, [mineChats, otherChats]);

    const requestChatsData = () => {
        appApi.get(`/freight_matches/me/mine`).then(res => {
            setMineChats(res.data.data);
        });
        appApi.get(`/freight_matches/me/others`).then(res => {
            setOtherChats(res.data.data);
        });
    }

    useEffect(() => {
        socket.on('new-message', () => {
            requestChatsData();
        })
    }, []);

    const handleSelectedChat = (id) => {
        const temp = mine ? mineChats.find((item) => item.id === id) : otherChats.find((item) => item.id === id);
        setSelectedChatData(temp);
    }

    const sendMessage = (text) => {
        const message = {
            message: text,
            toCompanyId: mine ? selectedChatData.match_company_id : selectedChatData.proposal_company_id,
            freightMatchId: selectedChatData.id,
        };
        socket.emit('send-message', message)    
    }

    return(
        <ChatContext.Provider value={{ 
            chats: mine ? mineChats : otherChats, 
            selectedChatData, 
            handleSelectedChat, 
            mine, 
            setMine,
            sendMessage,
        }}>
            {children}
        </ChatContext.Provider>
    );

}